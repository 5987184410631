import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import FullPageDialog from "components/FullPageDialog";

import ParentForm from "entities/Parent/components/Form";

import { incomeEligibleParentCreate, IParent } from "entities/Parent/sdk";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

const ParentAddPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { voucherId: voucherIdParam } = useParams<{ voucherId: string }>();

  if (!voucherIdParam) {
    return null;
  }

  const handleSave = (values: Partial<IParent>) =>
    incomeEligibleParentCreate({ data: values }).then(() => {
      navigate(
        reverse(URLS.VERIFY_INCOME_PARENTS_LIST, {
          voucherId: voucherIdParam,
        })
      );
    });

  return (
    <FullPageDialog
      title={t("parentStatus")}
      backUrl={reverse(URLS.VERIFY_INCOME_PARENTS_LIST, {
        voucherId: voucherIdParam,
      })}
      closeUrl={URLS.HOME}
    >
      <ParentForm onSave={handleSave} />
    </FullPageDialog>
  );
};

export default ParentAddPage;
