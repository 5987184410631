import Box from "components/Box";
import IconButton from "components/IconButton";
import Text from "components/Text";
import Stack from "components/Stack";

import { EditIcon } from "icons";
import { colors } from "theme";
import { formatToCamelCase } from "utils/format";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

type IProps = {
  voucher: {
    type: string;
    created_at: string;
  };
  placement: {
    provider_name?: string;
    child_care_need?: string;
    updated_at: string;
    children: {
      first_name: string;
      last_name: string;
    }[];
  };
  onEdit: () => void;
  hasMissingFields?: boolean;
};

const PlacementListItem = ({
  placement,
  voucher,
  onEdit,
  hasMissingFields = false,
}: IProps) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: colors.outline,
        borderRadius: "8px",
        padding: "16px",
        marginBottom: "16px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: "100%",
      }}
    >
      <Stack gap="5px">
        <Text variant="titleMedium">
          Placement{" "}
          {placement.provider_name &&
            t(formatToCamelCase(placement.provider_name))}
        </Text>
        <Text variant="labelMedium">
          Last Activity: {dayjs(placement.updated_at).format("MM/DD/YYYY")}
        </Text>
        <Stack direction="row" justifyContent="space-between">
          <Text variant="titleMedium">
            {placement.provider_name &&
              t(formatToCamelCase(placement.provider_name))}
          </Text>
          <Text>
            <Text variant="titleMedium">
              {t(formatToCamelCase(voucher.type))} -{" "}
            </Text>
            {dayjs(voucher.created_at).format("MM/DD/YYYY")}
          </Text>
        </Stack>
        {hasMissingFields && <Text color="error">Incomplete</Text>}
      </Stack>
      <IconButton
        sx={{
          position: "absolute",
          top: "16px",
          right: "16px",
          height: "40px",
          width: "40px",
        }}
        onClick={(e) => {
          e.stopPropagation();
          onEdit();
        }}
      >
        <EditIcon />
      </IconButton>
    </Box>
  );
};

export default PlacementListItem;
