import _ from "lodash";
import { Navigate, useParams } from "react-router-dom";

import { useMyFamily } from "entities/Family/sdk";
import { CircularProgress } from "components/Loading";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

const VerificationRedericetToChildPage = () => {
  const { data: family } = useMyFamily();
  const { voucherId: vouchersIdParam } = useParams<{
    voucherId: string;
  }>();

  const childId: number | undefined = _.first(
    _.orderBy(family?.children, "id")
  )?.id;

  if (!childId || !vouchersIdParam) {
    return (
      <div
        style={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  // If the family has no childs - reroute to missing child info
  if (!childId && Boolean(family)) {
    return (
      <Navigate
        to={reverse(URLS.VERIFY_INCOME_CHILDREN_LIST, { vouchersIdParam })}
      />
    );
  }

  return (
    <Navigate
      to={reverse(URLS.VERIFY_INCOME_CITIZENSHIP_FOR_CHILD, {
        childId,
        voucherId: vouchersIdParam,
      })}
    />
  );
};

export default VerificationRedericetToChildPage;
