import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import FullPageDialog from "components/FullPageDialog";

import ChildForm from "entities/Child/components/Form";

import { useMyFamily } from "entities/Family/sdk";
import { childUpdateInUserFamily, IChild } from "entities/Child/sdk";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

const ChildEditPage = () => {
  const { t } = useTranslation();
  const { childId: childIdParam, voucherId: voucherIdParam } = useParams<{
    childId: string;
    voucherId: string;
  }>();
  const childId = parseInt(String(childIdParam));
  const navigate = useNavigate();
  const { data: family } = useMyFamily();
  const child = family?.children.find((child) => child.id === childId);

  const missingFieldsForThisChild = family?.missing_fields
    .flatMap((voucher) => voucher.missing_fields.child_missing_fields)
    .filter(
      (parentMissingFields) =>
        parentMissingFields.id === childId &&
        parentMissingFields.missing_fields.length > 0
    )
    .flatMap((parentMissingFields) => parentMissingFields.missing_fields);

  if (!child || !voucherIdParam) {
    return null;
  }

  const handleSave = (data: Partial<IChild>) => {
    return childUpdateInUserFamily({ childId, data: data }).then(() => {
      navigate(
        reverse(URLS.MISSING_INFORMATION_CHILDREN, {
          voucherId: voucherIdParam,
        })
      );
    });
  };

  return (
    <FullPageDialog
      title={t("childInformation")}
      progressBarPercentage={30}
      backUrl={reverse(URLS.MISSING_INFORMATION_CHILDREN, {
        voucherId: voucherIdParam,
      })}
      closeUrl={URLS.HOME}
    >
      <ChildForm
        initialValues={child}
        fieldsWithWarnings={missingFieldsForThisChild}
        onSave={handleSave}
      />
    </FullPageDialog>
  );
};

export default ChildEditPage;
