import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import FullPageDialog from "components/FullPageDialog";

import ParentForm from "entities/Parent/components/Form";

import { parentCreateInUserFamily, IParent } from "entities/Parent/sdk";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

const ParentAddPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { voucherId: voucherIdParam } = useParams<{ voucherId: string }>();

  if (!voucherIdParam) {
    return null;
  }

  const handleSave = (values: Partial<IParent>) => {
    const { preferred_name, ...rest } = values;

    const data = {
      ...rest,
      ...(preferred_name && preferred_name.trim() !== ""
        ? { preferred_name }
        : {}),
    };

    return parentCreateInUserFamily({ data }).then(() => {
      navigate(
        reverse(URLS.MISSING_INFORMATION_PARENTS, { voucherId: voucherIdParam })
      );
    });
  };
  return (
    <FullPageDialog
      title={t("parentStatus")}
      progressBarPercentage={20}
      backUrl={reverse(URLS.MISSING_INFORMATION_PARENTS, {
        voucherId: voucherIdParam,
      })}
      closeUrl={URLS.HOME}
    >
      <ParentForm onSave={handleSave} />
    </FullPageDialog>
  );
};

export default ParentAddPage;
