import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";

import _ from "lodash";

import Stack from "components/Stack";
import BasicButton from "components/BasicButton";
import Container from "components/Container";
import TextField from "components/TextField";
import useMediaQuery from "components/useMediaQuery";
import FormControlLabel from "components/FormControlLabel";
import Checkbox from "components/Checkbox";

import DateInput from "components/DateInput";

import theme, { colors } from "theme";
import { useFormWarnings } from "utils/forms";
import { IDependent } from "entities/Dependent/sdk";
import { IFamilyVoucher } from "entities/Family/sdk";

interface IProps {
  initialValues?: Partial<IDependent>;
  fieldsWithWarnings?: string[];
  onSave: (values: Partial<IDependent>) => Promise<any>;
  vouchers?: Array<IFamilyVoucher>;
}

const DependentForm = ({
  initialValues,
  fieldsWithWarnings,
  onSave,
  vouchers,
}: IProps) => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:600px)");

  const { control, handleSubmit, formState, setError } = useForm<IDependent>({
    defaultValues: {
      ...initialValues,
      date_of_birth: initialValues?.date_of_birth
        ? dayjs(initialValues?.date_of_birth).format("MM/DD/YYYY")
        : undefined,
      is_dependent_included_in_voucher: initialValues
        ? _.some(vouchers, (voucher) =>
            _.find(voucher.dependents, { id: initialValues.id })
          )
        : true,
    },
  });
  const { fieldWarningsAndErrors } = useFormWarnings({
    formState,
    fieldsWithWarnings,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = (data: IDependent) => {
    const formattedBirthDate = data.date_of_birth
      ? dayjs(data.date_of_birth, "MM/DD/YYYY").format("YYYY-MM-DD")
      : undefined;

    const dependentData = {
      ...data,
      date_of_birth: formattedBirthDate,
    };

    setIsSubmitting(true);
    onSave(dependentData)
      .catch((errors) => {
        errors.setFormErrors && errors.setFormErrors(setError);
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <>
      <Stack alignItems={"center"} sx={{ flex: 1 }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            width: "100%",
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack
            sx={{ flex: 1 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Container
              maxWidth="sm"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                flex: 1,
                height: "100%",
              }}
            >
              <Stack sx={{ width: "100%" }}>
                <Controller
                  name="first_name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("firstName")}
                      fullWidth
                      margin="normal"
                      {...fieldWarningsAndErrors(field)}
                    />
                  )}
                />
                <Controller
                  name="last_name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("lastName")}
                      fullWidth
                      margin="normal"
                      {...fieldWarningsAndErrors(field)}
                    />
                  )}
                />
                <Controller
                  name="date_of_birth"
                  control={control}
                  render={({ field }) => (
                    <DateInput
                      {...field}
                      control={control}
                      label={t("dateOfBirth")}
                      required={false}
                      {...fieldWarningsAndErrors(field)}
                    />
                  )}
                />
                {initialValues && (
                  <Controller
                    name="is_dependent_included_in_voucher"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label={t("currentlyADependent")}
                      />
                    )}
                  />
                )}
              </Stack>
            </Container>
            <Stack
              sx={{
                width: "100%",
                borderTop: matches ? "1px solid" : "none",
                borderColor: colors.outline,
                alignItems: "center",
              }}
            >
              <Stack
                sx={{ padding: theme.spacing(4, 1), width: "100%" }}
                alignItems="center"
              >
                <BasicButton
                  buttonType="submit"
                  label={t("next")}
                  isDisabled={isSubmitting}
                />
              </Stack>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default DependentForm;
