import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Stack from "components/Stack";
import Text from "components/Text";
import BasicButton from "components/BasicButton";
import Container from "components/Container";
import FullPageDialog from "components/FullPageDialog";

import { colors } from "theme";

import CompleteImage from "assets/complete.png";

import { URLS } from "config/urls";

const MissingInformationSuccessReferralPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToHomePage = () => {
    navigate(URLS.HOME);
  };
  return (
    <FullPageDialog
      title={t("successScreenTitle")}
      progressBarPercentage={99}
      closeUrl={URLS.HOME}
    >
      <Stack
        sx={{
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            margin: "30px auto",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
          }}
        >
          <img
            src={CompleteImage}
            alt="Completed"
            style={{ maxWidth: "400px" }}
          />
          <Text
            variant="titleMedium"
            color={colors.onSurface}
            sx={{ margin: (theme) => theme.spacing(6, 0, 2, 0) }}
          >
            {t("docusignMessageIntro")}
          </Text>
          <Text variant="titleMedium" color={colors.onSurface}>
            {t("docusignMessageAction")}
          </Text>
        </Container>
        <Stack
          sx={{
            padding: (theme) => theme.spacing(4, 1),
            alignItems: "center",
          }}
        >
          <BasicButton
            label={t("home")}
            backgroundColor={colors.secondaryContainer}
            color={colors.onSecondaryContainer}
            onHoverBackground={colors.onHoverSecondaryContainer}
            onClick={goToHomePage}
          />
        </Stack>
      </Stack>
    </FullPageDialog>
  );
};

export default MissingInformationSuccessReferralPage;
