import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Stack from "components/Stack";
import Text from "components/Text";
import BasicButton from "components/BasicButton";
import Link from "components/Link";
import FullPageDialog from "components/FullPageDialog";
import Container from "components/Container";

import { colors } from "theme";

import CompleteImage from "assets/complete.png";

import { URLS } from "config/urls";

const MissingInformationSuccessWaitlistPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToHomePage = () => {
    navigate(URLS.HOME);
  };
  return (
    <FullPageDialog
      title={t("successScreenTitle")}
      progressBarPercentage={99}
      closeUrl={URLS.HOME}
    >
      <Stack
        sx={{
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            margin: "30px auto",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
          }}
        >
          <img
            src={CompleteImage}
            alt="Completed"
            style={{ maxWidth: "400px" }}
          />
          <Text
            variant="titleLarge"
            sx={{ textAlign: "center", margin: (theme) => theme.spacing(6, 0) }}
          >
            {t("completeHeader")}
          </Text>
          <Text variant="titleMedium">{t("familyAdministratorFollowUp")}</Text>
          <Stack alignItems={"center"} gap="10px" sx={{ mt: "50px" }}>
            <Text variant="titleSmall" color={colors.onSurfaceVariant}>
              {t("promptDontWantToWait")}
            </Text>
            <Link
              to={"https://booknow.appointment-plus.com/180n6yhv/"}
              external
              target="_blank"
            >
              <Text variant="titleSmall" color={colors.primary}>
                {t("actionBookAppointment")}
              </Text>
            </Link>
            <Text variant="titleSmall" color={colors.onSurfaceVariant}>
              {t("descriptionWithFamilyAdministrator")}
            </Text>
          </Stack>
        </Container>
        <Stack
          sx={{
            padding: (theme) => theme.spacing(4, 1),
            alignItems: "center",
          }}
        >
          <BasicButton
            label={t("home")}
            backgroundColor={colors.secondaryContainer}
            color={colors.onSecondaryContainer}
            onHoverBackground={colors.onHoverSecondaryContainer}
            onClick={goToHomePage}
          />
        </Stack>
      </Stack>
    </FullPageDialog>
  );
};

export default MissingInformationSuccessWaitlistPage;
