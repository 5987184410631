import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import _ from "lodash";

import FullPageDialog from "components/FullPageDialog";

import { URLS } from "config/urls";

import IncomeEligibleChildForm from "entities/Child/components/IncomeEligibleChildForm";
import { useMyFamily } from "entities/Family/sdk";
import {
  IIncomeEligibleChild,
  incomeEligibleChildUpdate,
} from "entities/Child/sdk";
import { reverse } from "utils/urls";

const ChildEditPage = () => {
  const { t } = useTranslation();
  const { childId: childIdParam } = useParams<{ childId: string }>();
  const childId = parseInt(String(childIdParam));
  const navigate = useNavigate();
  const { data: family } = useMyFamily();
  const child = family?.children.find((child) => child.id === childId);
  const { voucherId: voucherIdParam } = useParams<{ voucherId: string }>();
  const voucherId = voucherIdParam ? parseInt(voucherIdParam) : undefined;
  const isChildInVoucher = _.some(
    family?.vouchers,
    (voucher) =>
      voucher.id === voucherId && _.some(voucher.children, { id: childId })
  );
  if (!voucherId) {
    return null;
  }
  const handleSave = (data: Partial<IIncomeEligibleChild>) => {
    return incomeEligibleChildUpdate({ childId, voucherId, data }).then(() => {
      navigate(
        reverse(URLS.VERIFY_INCOME_CHILDREN_LIST, {
          voucherId,
        })
      );
    });
  };

  if (!child) return null;

  return (
    <FullPageDialog
      title={t("childInformation")}
      backUrl={reverse(URLS.VERIFY_INCOME_CHILDREN_LIST, {
        voucherId,
      })}
      closeUrl={URLS.HOME}
    >
      <IncomeEligibleChildForm
        initialValues={child}
        onSave={handleSave}
        isChildInVoucher={isChildInVoucher}
      />
    </FullPageDialog>
  );
};

export default ChildEditPage;
