import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import FullPageDialog from "components/FullPageDialog";

import { useMyFamily } from "entities/Family/sdk";
import { reverse } from "utils/urls";

import { authorizationHouseholdSizeUpdate } from "entities/Authorization/sdk";
import IncomeVerificationForm from "entities/Authorization/components/IncomeVerificationForm";

import { URLS } from "config/urls";

const IncomeVerificationPage = () => {
  const { t } = useTranslation();
  const { data: family, isLoading } = useMyFamily();

  const navigate = useNavigate();
  const { voucherId: voucherIdParam } = useParams<{ voucherId: string }>();

  if (isLoading || !family) {
    return null;
  }

  const voucher = family.vouchers.find(
    (voucher) => voucher.id === parseInt(voucherIdParam ?? "")
  );

  if (!voucher || !voucherIdParam) {
    return null;
  }

  const handleSave = (data: any) => {
    return authorizationHouseholdSizeUpdate({
      authorizationId: voucherIdParam,
      data: {
        ...data,
        total_annual_income: data?.total_annual_income,
      },
    }).then(() => {
      navigate(
        reverse(URLS.MISSING_INFORMATION_SPECIAL_CIRCUMSTANCES, {
          voucherId: voucherIdParam,
        })
      );
    });
  };

  return (
    <FullPageDialog
      title={t("incomeVerification")}
      progressBarPercentage={40}
      backUrl={reverse(URLS.MISSING_INFORMATION_CHILDREN, {
        voucherId: voucherIdParam,
      })}
      closeUrl={URLS.HOME}
    >
      <IncomeVerificationForm voucher={voucher} onSave={handleSave} />
    </FullPageDialog>
  );
};

export default IncomeVerificationPage;
