import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import FullPageDialog from "components/FullPageDialog";

import ParentForm from "entities/Parent/components/Form";

import { incomeEligibleParentUpdate, IParent } from "entities/Parent/sdk";
import { useMyFamily } from "entities/Family/sdk";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

const ParentEditPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { parentId: parentIdParam } = useParams<{ parentId: string }>();
  const parentId = parseInt(String(parentIdParam));
  const { data: family } = useMyFamily();
  const { voucherId: voucherIdParam } = useParams<{ voucherId: string }>();

  const parent = family?.family_members.find(
    (parent) => parent.id === parentId
  );

  if (!parent || !voucherIdParam) {
    return null;
  }

  const handleSave = (data: Partial<IParent>) => {
    return incomeEligibleParentUpdate({ parentId, data }).then(() => {
      navigate(
        reverse(URLS.VERIFY_INCOME_PARENTS_LIST, {
          voucherId: voucherIdParam,
        })
      );
    });
  };

  return (
    <FullPageDialog
      title={t("parentStatus")}
      backUrl={reverse(URLS.VERIFY_INCOME_PARENTS_LIST, {
        voucherId: voucherIdParam,
      })}
      closeUrl={URLS.HOME}
    >
      <ParentForm initialValues={parent} onSave={handleSave} />
    </FullPageDialog>
  );
};

export default ParentEditPage;
