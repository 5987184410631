import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import dayjs from "dayjs";

import { toast } from "react-toastify";

import Stack from "components/Stack";
import StyledSelect from "components/StyledSelect";
import BasicButton from "components/BasicButton";
import useMediaQuery from "components/useMediaQuery";
import DateInput from "components/DateInput";
import TextField from "components/TextField";
import MultiSelect from "components/MultiSelect";

import {
  parsedVoucherUpdate,
  // DCFReferralUpdate,
  IDetailDocumentData,
  useDocumentsOptions,
} from "entities/Documents/sdk";

import { colors } from "theme";
import { reverse } from "utils/urls";
import { URLS } from "config/urls";

interface IProps {
  data: IDetailDocumentData;
  documentId: number;
}
const DCFReferralAuthorization = ({ data, documentId }: IProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    authorizationTypeOptions,
    childCareStatusOptions,
    serviceNeedOptions,
  } = useDocumentsOptions();
  const matches = useMediaQuery("(min-width:600px)");

  const dcf_data = data.family_data.voucher_data;
  const { control, getValues } = useForm({
    defaultValues: {
      authorizationType: data.document_type,
      referralDate: dayjs(dcf_data?.referral_date || new Date()).format(
        "MM/DD/YYYY"
      ),
      serviceNeed: dcf_data?.service_need || "",
      dcfCaseNumber: dcf_data?.dcf_case_number || "",
      dcfAuthorizationNumber: dcf_data?.dcf_authorization_number || "",
      dcfRegion: dcf_data?.dcf_region || "",
      childCareStatus: dcf_data?.child_care_status || "",
      children: data.family_data.voucher_data.children.map((child) =>
        String(child.id)
      ),
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSave = () => {
    if (dcf_data) {
      const values = getValues();

      const dcfData = {
        authorization_type: data.document_type,
        // referral_date: values.referralDate,
        referral_date: dayjs(values.referralDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        ),
        service_need: values.serviceNeed,
        dcf_case_number: values.dcfCaseNumber,
        dcf_authorization_number: values.dcfAuthorizationNumber,
        dcf_region: values.dcfRegion,
        child_care_status: values.childCareStatus,
        children: values.children,
      };

      setIsSubmitting(true);
      parsedVoucherUpdate({ parsedVoucherId: dcf_data.id, data: dcfData })
        .then(() => {
          setIsSubmitting(false);
          navigate(
            reverse(URLS.FAMILY_SPECIALIST_DOCUMENTS, {
              documentId: String(documentId),
              stepname: "placement",
            })
          );
        })
        .catch(() => {
          const errorMessage = t("errorSavingData");
          toast.error(errorMessage);
          setIsSubmitting(false);
        });
    }
  };

  const childrenOptions = data.family_data.children_data.map((child) => ({
    label: `${child.first_name} ${child.last_name}`,
    value: String(child.id),
  }));

  return (
    <>
      <form>
        <Stack spacing={2}>
          <Controller
            name="children"
            control={control}
            rules={{ required: t("fieldRequired") }}
            render={({ field }) => (
              <MultiSelect
                {...field}
                label={t("childrenAuthorized")}
                // error={!!formMethods.formState.errors.children}
                // helperText={formMethods.formState.errors.children?.message}
                options={childrenOptions}
              />
            )}
          />

          <Controller
            name="authorizationType"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("authorizationType")}
                options={authorizationTypeOptions}
                disabled
              />
            )}
          />

          <DateInput
            label={t("referralDate")}
            name="referralDate"
            control={control}
          />
          <Controller
            name="dcfCaseNumber"
            control={control}
            render={({ field }) => (
              <TextField {...field} label={t("dcfCaseNumber")} />
            )}
          />
          <Controller
            name="dcfAuthorizationNumber"
            control={control}
            render={({ field }) => (
              <TextField {...field} label={t("dcfAuthorizationNumber")} />
            )}
          />
          <Controller
            name="dcfRegion"
            control={control}
            render={({ field }) => (
              <TextField {...field} label={t("dcfRegion")} />
            )}
          />
          <Controller
            name="childCareStatus"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("childCareStatus")}
                options={childCareStatusOptions}
              />
            )}
          />
          <Controller
            name="serviceNeed"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("serviceNeed")}
                options={serviceNeedOptions}
              />
            )}
          />
        </Stack>
      </form>
      <Stack
        sx={{
          borderTop: matches ? "1px solid" : "none",
          borderColor: colors.outline,
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
          }}
        >
          <BasicButton
            label={t("save")}
            onClick={handleSave}
            isDisabled={isSubmitting}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default DCFReferralAuthorization;
