import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import BasicButton from "components/BasicButton";
import Container from "components/Container";
import Stack from "components/Stack";
import FullPageDialog from "components/FullPageDialog";
import Text from "components/Text";
import AddButton from "components/AddButton";
import Link from "components/Link";
import IconButton from "components/IconButton";

import theme, { colors } from "theme";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";
import { EditIcon } from "icons";

import { useMyFamily } from "entities/Family/sdk";

const DependentsListPage = () => {
  const { t } = useTranslation();
  const { data: familyData } = useMyFamily();
  const navigate = useNavigate();
  const { voucherId: voucherIdParam } = useParams<{ voucherId: string }>();

  if (!familyData || !voucherIdParam) {
    return null;
  }

  const handleNextClick = () => {
    navigate(
      reverse(URLS.VERIFY_INCOME_PARENT_VERIFICATION, {
        voucherId: voucherIdParam,
      })
    );
  };

  return (
    <FullPageDialog
      title={t("dependents")}
      backUrl={reverse(URLS.VERIFY_INCOME_CHILDREN_LIST, {
        voucherId: voucherIdParam,
      })}
      closeUrl={URLS.HOME}
    >
      <Stack
        sx={{
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Container maxWidth="sm" sx={{ flex: 1, marginTop: theme.spacing(2) }}>
          <div style={{ margin: theme.spacing(2, 0) }}>
            <Text
              component="p"
              variant="bodyLarge"
              color={colors.onSurface}
              fontWeight={600}
              sx={{ mb: theme.spacing(2) }}
            >
              {t("addOtherDependents")}
            </Text>
            <Text component="p" variant="bodyLarge" color={colors.onSurface}>
              {t("dependentDefinition")}
            </Text>
          </div>
          <Stack gap={theme.spacing(2)} flex={1} alignItems="center">
            {familyData.dependents.map((dependent) => (
              <Stack
                direction="row"
                key={dependent.id}
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  borderTop: "1px solid",
                  borderBottom: "1px solid",
                  borderColor: colors.outline,
                  padding: theme.spacing(1),
                  width: "100%",
                }}
              >
                <Stack gap="15px">
                  <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
                    {`${dependent.first_name} ${dependent.last_name}`.trim() ===
                      "" && "Unknown name"}
                    {dependent.first_name} {dependent.last_name}
                  </Text>
                </Stack>
                <Link
                  to={reverse(URLS.VERIFY_INCOME_EDIT_DEPENDENT, {
                    dependent: dependent.id,
                    voucherId: voucherIdParam,
                  })}
                >
                  <IconButton
                    sx={{
                      height: "40px",
                      width: "40px",
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Link>
              </Stack>
            ))}
            <Link
              to={reverse(URLS.VERIFY_INCOME_ADD_DEPENDENT, {
                voucherId: voucherIdParam,
              })}
            >
              <AddButton
                label={t("addDependentButton")}
                backgroundColor={colors.secondaryContainer}
              />
            </Link>
          </Stack>
        </Container>
        <div
          style={{
            padding: theme.spacing(2, 3),
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <BasicButton label={t("next")} onClick={handleNextClick} />
        </div>
      </Stack>
    </FullPageDialog>
  );
};
export default DependentsListPage;
