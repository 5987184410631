import Box from "components/Box";
import IconButton from "components/IconButton";
import Text from "components/Text";
import Stack from "components/Stack";

import { EditIcon } from "icons";
import { colors } from "theme";
import dayjs from "dayjs";

type IProps = {
  dependent: {
    first_name: string;
    last_name: string;
    date_of_birth: string;
  };
  onEdit: () => void;
};

const DependentListItem = ({ dependent, onEdit }: IProps) => {
  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: colors.outline,
        borderRadius: "8px",
        padding: "16px",
        marginBottom: "16px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: "100%",
      }}
    >
      <Stack gap="5px">
        <Text variant="titleMedium">
          {(dependent.first_name || dependent.last_name) &&
            `${dependent.first_name} ${dependent.last_name}`}

          {!dependent.first_name && !dependent.last_name && "No Dependent Name"}
        </Text>
        <Text variant="bodyMedium">
          DOB:{" "}
          {dependent.date_of_birth &&
            dayjs(dependent.date_of_birth).format("MM/DD/YYYY")}
        </Text>
      </Stack>
      <IconButton
        sx={{
          position: "absolute",
          top: "16px",
          right: "16px",
          height: "40px",
          width: "40px",
        }}
        onClick={(e) => {
          e.stopPropagation();
          onEdit();
        }}
      >
        <EditIcon />
      </IconButton>
    </Box>
  );
};

export default DependentListItem;
