import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import BasicButton from "components/BasicButton";
import Container from "components/Container";
import FullPageDialogWithLanguage from "components/FullPageDialogWithLanguage";
import Stack from "components/Stack";
import Text from "components/Text";

import PrimaryContactVerifyInformationForm, {
  VerifyPrimaryInformationRef,
} from "entities/PrimaryContact/components/VerifyInformationForm";
import { useMyFamily } from "entities/Family/sdk";

import theme from "theme";

const ContactInfoPage = () => {
  const { t } = useTranslation();
  const formRef = useRef<VerifyPrimaryInformationRef>(null);
  const { data: familyData } = useMyFamily();
  const { voucherId: voucherIdParam } = useParams<{ voucherId: string }>();

  if (!familyData || !voucherIdParam) {
    return null;
  }

  const initialValues = {
    first_name: familyData.creator.first_name,
    last_name: familyData.creator.last_name,
    preferred_contact_method: familyData.creator.preferred_contact_method,
    preferred_language: familyData.creator.preferred_language,
    email: familyData.creator.email_contact_methods[0]?.email,
    phone_number: familyData.creator.phone_contact_methods[0]?.phone_number,
    date_of_birth: familyData.creator.date_of_birth,
  };

  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  return (
    <FullPageDialogWithLanguage>
      <Container maxWidth="sm" sx={{ flex: 1, marginTop: theme.spacing(2) }}>
        <Stack spacing={2} sx={{ height: "100%" }}>
          <Text variant="titleLarge">{t("confirmContactInfo")}</Text>
          <div style={{ flex: 1 }}>
            <PrimaryContactVerifyInformationForm
              ref={formRef}
              initialValues={initialValues}
              voucherId={voucherIdParam}
            />
          </div>
          <div
            style={{
              padding: theme.spacing(2, 3),
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <BasicButton
              label={t("next")}
              onClick={handleFormSubmit}
            ></BasicButton>
          </div>
        </Stack>
      </Container>
    </FullPageDialogWithLanguage>
  );
};

export default ContactInfoPage;
