import { post } from "utils/sdk";
import { BASE_URL } from "config/urls";

export interface IChild {
  first_name: string;
  last_name: string;
  gender: "MEN" | "WOMEN";
  preferred_language: string;
  date_of_birth: string | null;
  relationship: string;
  date_when_child_care_is_needed: string;
  child_has_a_disability: boolean;
  child_is_in_head_start_and_needs_more_hours_of_coverage: boolean;
}

export interface IIncomeEligibleChild {
  first_name: string;
  last_name: string;
  gender: "MEN" | "WOMEN";
  preferred_language: string;
  date_of_birth: string | null;
  relationship: string;
  child_has_a_disability: boolean;
  is_child_included_in_voucher: boolean;
}

export const childCreateInUserFamily = ({ data }: { data: Partial<IChild> }) =>
  post(`${BASE_URL}/api/family-members/child/create/`, data);

export const childUpdateInUserFamily = ({
  childId,
  data,
}: {
  childId: number;
  data: Partial<IChild>;
}) => post(`${BASE_URL}/api/family-members/child/${childId}/update/`, data);

export const childCreateAsSpecialist = (id: number, data: IChild) =>
  post(`${BASE_URL}/api/family-specialists/family/${id}/child/create/`, data);

export const childCreateInNewFamilyAsSpecialist = (id: number, data: IChild) =>
  post(
    `${BASE_URL}/api/family-specialists/new-family/${id}/child/create/`,
    data
  );

export const childUpdateAsSpecialist = (
  id: number,
  child_id: number,
  data: IChild
) =>
  post(
    `${BASE_URL}/api/family-specialists/family/${id}/child/${child_id}/update/ `,
    data
  );

export const incomeEligibleChildCreate = ({
  data,
  voucherId,
}: {
  data: Partial<IIncomeEligibleChild>;
  voucherId: number
}) =>
  post(`${BASE_URL}/api/family-members/income-eligible/${voucherId}/child/create/ `, data);

export const incomeEligibleChildUpdate = ({
  childId,
  voucherId,
  data,
}: {
  childId: number;
  voucherId: number;
  data: Partial<IIncomeEligibleChild>;
}) =>
  post(
    `${BASE_URL}/api/family-members/income-eligible/${voucherId}/child/${childId}/update/ `,
    data
  );
