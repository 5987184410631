import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import BasicButton from "components/BasicButton";
import Container from "components/Container";
import Stack from "components/Stack";
import FullPageDialog from "components/FullPageDialog";

import FamilyOverviewForm from "entities/Family/components/OverviewForm";

import theme from "theme";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

const OverviewPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { voucherId: voucherIdParam } = useParams<{ voucherId: string }>();

  if (!voucherIdParam) {
    return null;
  }

  const handleNextClick = () => {
    navigate(
      reverse(URLS.VERIFY_INCOME_PARENTS_LIST, {
        voucherId: voucherIdParam,
      })
    );
  };

  return (
    <FullPageDialog
      title={t("overview")}
      backUrl={reverse(URLS.VERIFY_INCOME_CHILD_CARE_NEEDS, {
        voucherId: voucherIdParam,
      })}
      closeUrl={URLS.HOME}
    >
      <Container maxWidth="sm" sx={{ flex: 1, marginTop: theme.spacing(2) }}>
        <Stack
          spacing={2}
          sx={{ height: "100%", justifyContent: "space-between" }}
        >
          <div style={{ flex: 1 }}>
            <FamilyOverviewForm />
          </div>
          <div
            style={{
              padding: theme.spacing(2, 3),
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <BasicButton label={t("next")} onClick={handleNextClick} />
          </div>
        </Stack>
      </Container>
    </FullPageDialog>
  );
};
export default OverviewPage;
