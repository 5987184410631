import _ from "lodash";
import dayjs from "dayjs";
import { IFamilyDetail } from "entities/FamilySpecialist/sdk";

import { snakeCaseToCapitalisedText } from "utils/common";

interface IListItem {
  title: string;
  items?: IListItem[];
}

export const getMissingFieldsBullets = (
  familyData: IFamilyDetail
): IListItem[] => {
  const result = [];
  const missingFields = familyData.missing_fields;

  const familyErrors = _.uniq(
    missingFields.flatMap((m) => m.missing_fields.family_missing_fields)
  );

  const authorizationErrors = _.uniq(
    missingFields.flatMap((m) => m.missing_fields.voucher_missing_fields)
  );

  const parentErrors = [];
  for (const m of missingFields) {
    for (const field of m.missing_fields.parent_missing_fields) {
      const parent = familyData.family_members.find(
        (member) => member.id === field.id
      );

      for (const missing_field_name of field.missing_fields) {
        parentErrors.push(
          `${parent?.first_name} ${parent?.last_name} ${snakeCaseToCapitalisedText(missing_field_name)}`
        );
      }
    }
  }

  const childErrors = [];
  for (const m of missingFields) {
    for (const field of m.missing_fields.child_missing_fields) {
      for (const missing_field_name of field.missing_fields) {
        const child = familyData.children.find(
          (child) => child.id === field.id
        );
        childErrors.push(
          `${child?.first_name} ${child?.last_name} ${snakeCaseToCapitalisedText(missing_field_name)}`
        );
      }
    }
  }

  const missingPlacements = [];
  for (const m of missingFields) {
    for (const field of m.missing_fields.child_with_missing_placement) {
      const child = familyData.children.find(
        (child) => child.id === field.child_id
      );
      missingPlacements.push(
        `${child?.first_name} ${child?.last_name} is missing a placement`
      );
    }
  }

  const globalErrors = [
    ...new Set(missingFields.map((m) => m.missing_fields.global_errors).flat()),
  ];

  const missingDocuments = [
    ...new Set(
      missingFields.map((m) => m.missing_fields.missing_documents).flat()
    ),
  ];

  if (childErrors.length + missingPlacements.length > 0) {
    result.push({
      title: `Child - ${childErrors.length + missingPlacements.length} errors`,
      items: childErrors.concat(missingPlacements).map((e) => ({
        title: e,
      })),
    });
  }

  if (parentErrors.length > 0) {
    result.push({
      title: `Parent - ${parentErrors.length} errors`,
      items: parentErrors.map((e) => ({ title: e })),
    });
  }

  if (authorizationErrors.length > 0) {
    result.push({
      title: `Authorization - ${authorizationErrors.length} errors`,
      items: authorizationErrors.map((e) => ({ title: e })),
    });
  }

  if (familyErrors.length + globalErrors.length > 0) {
    result.push({
      title: `Family - ${familyErrors.length + globalErrors.length} errors`,
      items: familyErrors.concat(globalErrors).map((e) => ({ title: e })),
    });
  }

  if (missingDocuments.length > 0) {
    result.push({
      title: `Documents - ${missingDocuments.length} missing`,
      items: missingDocuments.map((e) => ({ title: e })),
    });
  }

  for (const m of missingFields) {
    const errors = [];
    for (const field of m.missing_fields.placement_missing_fields) {
      const placement = familyData.vouchers
        .flatMap((v) => v.placements)
        .find((p) => p.id === field.id);
      const placementErrors = [];

      for (const missing_field_name of field.missing_fields) {
        placementErrors.push(
          `${snakeCaseToCapitalisedText(missing_field_name)}`
        );
      }
      if (placementErrors.length > 0) {
        errors.push({ placement, placementErrors });
      }
    }

    if (errors.length > 0) {
      const authorization = familyData.vouchers.find(
        (a) => a.id === m.voucher_id
      );

      result.push({
        title: `${authorization?.type} - ${dayjs(authorization?.created_at).format("MM/DD/YYYY")}`,
        items: errors.map((e) => ({
          title: e.placement?.provider_name || "Unknown Provider",
          items: e.placementErrors.map((e) => ({ title: e })),
        })),
      });
    }
  }

  return result;
};
