import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import FullPageDialog from "components/FullPageDialog";

import { URLS } from "config/urls";

import IncomeEligibleChildForm from "entities/Child/components/IncomeEligibleChildForm";
import {
  IIncomeEligibleChild,
  incomeEligibleChildCreate,
} from "entities/Child/sdk";
import { reverse } from "utils/urls";

const ChildEditPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { voucherId: voucherIdParam } = useParams<{ voucherId: string }>();
  const voucherId = voucherIdParam ? parseInt(voucherIdParam) : undefined;
  if (!voucherId) {
    return null;
  }
  const handleSave = (values: Partial<IIncomeEligibleChild>) => {
    return incomeEligibleChildCreate({ data: values, voucherId }).then(() => {
      navigate(
        reverse(URLS.VERIFY_INCOME_CHILDREN_LIST, {
          voucherId,
        })
      );
    });
  };

  return (
    <FullPageDialog
      title={t("addChild")}
      backUrl={reverse(URLS.VERIFY_INCOME_CHILDREN_LIST, {
        voucherId,
      })}
      closeUrl={URLS.HOME}
    >
      <IncomeEligibleChildForm onSave={handleSave} />
    </FullPageDialog>
  );
};

export default ChildEditPage;
