import { useTranslation } from "react-i18next";

import Stack from "components/Stack";
import Text from "components/Text";

import { useDocumentsOptions } from "entities/Documents/sdk";
import { Controller } from "react-hook-form";
import StyledSelect from "components/StyledSelect";
import { useFormWarnings } from "utils/forms";

interface WeeklyScheduleProps {
  control: any;
  formState: any;
  fieldsWithWarnings?: string[];
}

const WeeklySchedule = ({
  control,
  formState,
  fieldsWithWarnings,
}: WeeklyScheduleProps) => {
  const { t } = useTranslation();
  const { placementOptions, transportOptions } = useDocumentsOptions();
  const { fieldWarningsAndErrors } = useFormWarnings({
    formState,
    fieldsWithWarnings,
  });

  const daysOfWeek = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  return (
    <Stack>
      {daysOfWeek.map((day) => (
        <Stack>
          <Text variant="labelLarge" fontWeight={"600"} mt={3}>
            {day.charAt(0).toUpperCase() + day.slice(1)}
          </Text>
          <Controller
            name={`${day}_schedule`}
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("schedule")}
                options={placementOptions}
                fullWidth
                margin="normal"
                {...fieldWarningsAndErrors(field)}
              />
            )}
          />
          <Controller
            name={`${day}_transportation`}
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("transportationNeeds")}
                options={transportOptions}
                fullWidth
                margin="normal"
                {...fieldWarningsAndErrors(field)}
              />
            )}
          />
        </Stack>
      ))}
    </Stack>
  );
};

export default WeeklySchedule;
