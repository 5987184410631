import axios from "axios";
import { post, useFetch } from "utils/sdk";

import { BASE_URL } from "config/urls";

import { CompletedStatus } from "entities/FamilySpecialist/sdk";

export interface ISignUpData {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  preferred_contact_method: string;
  consent_text: boolean;
  consent_email: boolean;
}

interface SIgnUpResponse {
  magic_token: string;
  user_type: string;
}

interface ILoginData {
  email?: string;
  phone_number?: string;
}

interface IResendMagicLinkData {
  email?: string;
  phone_number?: string;
  redirect_id: number;
}

export interface ILoginResponse {
  magic_token: string;
  user_type: string;
}

export enum UserType {
  FAMILY_MEMBER = "FAMILY_MEMBER",
  FAMILY_SPECIALIST = "FAMILY_SPECIALIST",
}

interface IUserData {
  id: number;
  email: string;
  user_type: UserType;
  vouchers: {
    status: CompletedStatus;
    type: string;
    id: number;
  }[];
  first_name: string;
  last_name: string;
  household_id: string;
}

export const useSignedUser = () => useFetch<IUserData>("/api/auth/me/");

export const getSignedUser = () =>
  axios.get<IUserData>(`${BASE_URL}/api/auth/me/`, { withCredentials: true });

export const signUp = (signUpData: ISignUpData) =>
  post<SIgnUpResponse>(`${BASE_URL}/api/family-members/register/`, signUpData);

export const signInMagicLink = (magicToken: string) =>
  post(`${BASE_URL}/api/users/login/`, {
    magic_token: magicToken,
  });

export const login = (loginData: ILoginData) =>
  post<ILoginResponse>(`${BASE_URL}/api/users/magic-link-login/`, loginData);

export const logout = () => post(`${BASE_URL}/api/users/logout/`);

export const resendMagicLink = (resendMagicLinkData: IResendMagicLinkData) =>
  post<ILoginResponse>(
    `${BASE_URL}/api/users/resend-magic-link/`,
    resendMagicLinkData
  );
