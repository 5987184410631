import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import _ from "lodash";

import Stack from "components/Stack";
import FullPageDialog from "components/FullPageDialog";
import MilitaryOrRetiredForm from "entities/Authorization/components/MilitaryOrRetiredForm";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

import { useMyFamily } from "entities/Family/sdk";
import { incomeEligibleMilitaryUpdate } from "entities/Parent/sdk";

const MilitaryOrRetiredPage = () => {
  const { t } = useTranslation();
  const { data: familyData } = useMyFamily();
  const { voucherId: vouchersIdParam } = useParams<{
    voucherId: string;
  }>();
  const voucherId = vouchersIdParam ? parseInt(vouchersIdParam) : undefined;

  const defaultValues = {
    military:
      familyData?.family_members.map((member) => ({
        parent: member.id,
        is_military_retired:
          member.parent_to_income_eligibles.find(
            (e) => e.voucher.id === voucherId
          )?.is_military_retired || false,
        is_in_active_military_duty:
          member.parent_to_income_eligibles.find(
            (e) => e.voucher.id === voucherId
          )?.is_in_active_military_duty || false,
      })) || [],
  };

  if (!familyData || !voucherId) {
    return null;
  }

  const parentById = _.keyBy(familyData.family_members, "id");

  const onSave = (data: typeof defaultValues) => {
    incomeEligibleMilitaryUpdate({ voucherId, data: data.military }).then(
      () => {
        // navigate(URLS.VERIFY_INCOME_CHILDREN_LIST);
      }
    );
  };

  return (
    <FullPageDialog
      title={t("militaryOrRetired")}
      backUrl={reverse(URLS.VERIFY_INCOME_RESIDENCY_VERIFICATION, {
        voucherId,
      })}
      closeUrl={URLS.HOME}
    >
      <Stack sx={{ justifyContent: "space-between", flex: 1 }}>
        <MilitaryOrRetiredForm
          defaultValues={defaultValues}
          parentById={parentById}
          onSave={onSave}
        />
      </Stack>
    </FullPageDialog>
  );
};
export default MilitaryOrRetiredPage;
