import { forwardRef, useImperativeHandle } from "react";

import { useTranslation } from "react-i18next";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import TextField from "components/TextField";
import DateInput from "components/DateInput";
import dayjs from "dayjs";

interface IProps {
  onSave: (data: Partial<IDependentFormValues>) => Promise<void>;
  initialData?: Partial<{
    first_name: string;
    last_name: string;
    date_of_birth: string;
  }>;
}

interface IDependentFormValues {
  first_name: string;
  last_name: string;
  date_of_birth: string;
}

export interface DependentFormRef {
  submitForm: () => void;
}

const DependentForm = forwardRef<DependentFormRef, IProps>(
  ({ onSave, initialData }, ref) => {
    const { t } = useTranslation();
    const { control, handleSubmit, formState, setError } =
      useForm<IDependentFormValues>({
        defaultValues: {
          first_name: initialData?.first_name,
          last_name: initialData?.last_name,
          date_of_birth: initialData?.date_of_birth
            ? dayjs(initialData?.date_of_birth).format("MM/DD/YYYY")
            : undefined,
        },
      });

    const onSubmit: SubmitHandler<IDependentFormValues> = (data) => {
      const formattedBirthDate = data.date_of_birth
        ? dayjs(data.date_of_birth, "MM/DD/YYYY").format("YYYY-MM-DD")
        : undefined;

      onSave({ ...data, date_of_birth: formattedBirthDate }).catch(
        (errors) => errors.setFormErrors && errors.setFormErrors(setError)
      );
    };

    useImperativeHandle(ref, () => ({
      submitForm: handleSubmit(onSubmit),
    }));

    return (
      <>
        <Controller
          name="first_name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("firstName")}
              fullWidth
              required={false}
              margin="normal"
              error={!!formState.errors.first_name}
              helperText={formState.errors.first_name?.message}
            />
          )}
        />
        <Controller
          name="last_name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("lastName")}
              fullWidth
              margin="normal"
              required={false}
              error={!!formState.errors.last_name}
              helperText={formState.errors.last_name?.message}
            />
          )}
        />
        <Controller
          name="date_of_birth"
          control={control}
          render={({ field }) => (
            <DateInput
              {...field}
              control={control}
              label={t("dateOfBirth")}
              required={false}
              error={!!formState.errors.last_name}
              helperText={formState.errors.last_name?.message}
            />
          )}
        />
      </>
    );
  }
);

export default DependentForm;
