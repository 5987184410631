import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import FullPageDialog from "components/FullPageDialog";

import { URLS } from "config/urls";

import DependentForm from "entities/Dependent/components/Form";
import {
  IDependent,
  incomeEligibleDependentCreate,
} from "entities/Dependent/sdk";
import { reverse } from "utils/urls";

const DependentAddPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { voucherId: voucherIdParam } = useParams<{ voucherId: string }>();
  const voucherId = voucherIdParam ? parseInt(voucherIdParam) : undefined;
  if (!voucherId) {
    return null;
  }
  const handleSave = (values: Partial<IDependent>) => {
    return incomeEligibleDependentCreate({ data: values, voucherId }).then(
      () => {
        navigate(
          reverse(URLS.VERIFY_INCOME_DEPENDENTS_LIST, {
            voucherId,
          })
        );
      }
    );
  };

  return (
    <FullPageDialog
      title={t("addDependent")}
      backUrl={reverse(URLS.VERIFY_INCOME_DEPENDENTS_LIST, {
        voucherId,
      })}
      closeUrl={URLS.HOME}
    >
      <DependentForm onSave={handleSave} />
    </FullPageDialog>
  );
};

export default DependentAddPage;
