import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import FullPageDialog from "components/FullPageDialog";

import ParentForm from "entities/Parent/components/Form";

import { parentUpdateInUserFamily, IParent } from "entities/Parent/sdk";
import { useMyFamily } from "entities/Family/sdk";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

const ParentEditPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { parentId: parentIdParam, voucherId: voucherIdParam } = useParams<{
    parentId: string;
    voucherId: string;
  }>();
  const parentId = parseInt(String(parentIdParam));
  const { data: family } = useMyFamily();

  const parent = family?.family_members.find(
    (parent) => parent.id === parentId
  );

  const missingFieldsForThisParent = family?.missing_fields
    .flatMap((voucher) => voucher.missing_fields.parent_missing_fields)
    .filter(
      (parentMissingFields) =>
        parentMissingFields.id === parentId &&
        parentMissingFields.missing_fields.length > 0
    )
    .flatMap((parentMissingFields) => parentMissingFields.missing_fields);

  if (!parent || !voucherIdParam) {
    return null;
  }

  const handleSave = (values: Partial<IParent>) => {
    const { preferred_name, ...rest } = values;

    const data = {
      ...rest,
      ...(preferred_name && preferred_name.trim() !== ""
        ? { preferred_name }
        : {}),
    };
    return parentUpdateInUserFamily({ parentId, data }).then(() => {
      navigate(
        reverse(URLS.MISSING_INFORMATION_PARENTS, { voucherId: voucherIdParam })
      );
    });
  };
  return (
    <FullPageDialog
      title={t("parentStatus")}
      progressBarPercentage={20}
      backUrl={reverse(URLS.MISSING_INFORMATION_PARENTS, {
        voucherId: voucherIdParam,
      })}
      closeUrl={URLS.HOME}
    >
      <ParentForm
        fieldsWithWarnings={missingFieldsForThisParent}
        initialValues={parent}
        onSave={handleSave}
      />
    </FullPageDialog>
  );
};

export default ParentEditPage;
