import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import Container from "components/Container";
import Divider from "components/Divider";
import Text from "components/Text";
import Stack from "components/Stack";
import FormControlLabel from "components/FormControlLabel";
import Checkbox from "components/Checkbox";
import BasicButton from "components/BasicButton";
import useMediaQuery from "components/useMediaQuery";

import { colors } from "theme";

import { IFamilyVoucher } from "entities/Family/sdk";

import { ISpecialCircumstances } from "entities/FamilyMember/sdk";

interface IProps {
  voucher?: Partial<IFamilyVoucher>;
  onSave: (data: ISpecialCircumstances) => Promise<void>;
}

const SpecialCircumstancesForm = ({ voucher, onSave }: IProps) => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:600px)");

  const { control, handleSubmit } = useForm<ISpecialCircumstances>({
    defaultValues: {
      family_has_experienced_domestic_violence:
        voucher?.family_has_experienced_domestic_violence || false,
      family_is_experiencing_homelessness:
        voucher?.family_is_experiencing_homelessness || false,
      parent_has_a_disability: voucher?.parent_has_a_disability || false,
      parent_is_a_member_of_the_us_military:
        voucher?.parent_is_a_member_of_the_us_military || false,
      parent_is_an_early_educator:
        voucher?.parent_is_an_early_educator || false,
      parent_is_receiving_treatment_for_substance_abuse:
        voucher?.parent_is_receiving_treatment_for_substance_abuse || false,
      teen_parent: voucher?.teen_parent || false,
      child_only_needs_coverage_during_school_closures:
        voucher?.child_only_needs_coverage_during_school_closures || false,
    },
  });

  return (
    <>
      <Stack
        sx={{
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            margin: "30px auto",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Stack alignItems={"center"}>
            <Text variant="titleMedium" sx={{ my: 3 }}>
              {t("priorityNotice")}
            </Text>
            <Stack
              sx={{ height: "100%" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack sx={{ width: matches ? "400px" : "100%" }}>
                <Controller
                  name="family_has_experienced_domestic_violence"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={t("domesticViolence")}
                    />
                  )}
                />
                <Divider sx={{ my: "10px" }} />
                <Controller
                  name="family_is_experiencing_homelessness"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={t("homelessness")}
                    />
                  )}
                />
                <Divider sx={{ my: "10px" }} />
                <Controller
                  name="parent_has_a_disability"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={t("parentDisability")}
                    />
                  )}
                />
                <Divider sx={{ my: "10px" }} />
                <Controller
                  name="parent_is_a_member_of_the_us_military"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={t("militaryMember")}
                    />
                  )}
                />
                <Divider sx={{ my: "10px" }} />
                <Controller
                  name="parent_is_an_early_educator"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={t("earlyEducator")}
                    />
                  )}
                />
                <Divider sx={{ my: "10px" }} />
                <Controller
                  name="parent_is_receiving_treatment_for_substance_abuse"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={t("substanceAbuseTreatment")}
                    />
                  )}
                />
                <Divider sx={{ my: "10px" }} />
                <Controller
                  name="teen_parent"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={t("teenParent")}
                    />
                  )}
                />
                <Divider sx={{ my: "10px" }} />
                <Controller
                  name="child_only_needs_coverage_during_school_closures"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={t("schoolClosureCoverage")}
                    />
                  )}
                />
                <Divider sx={{ my: "10px" }} />
              </Stack>
            </Stack>
          </Stack>
        </Container>
        <Stack
          sx={{
            borderTop: "1px solid " + colors.outline,
            padding: (theme) => theme.spacing(4, 1),
            alignItems: "center",
          }}
        >
          <BasicButton label={t("next")} onClick={handleSubmit(onSave)} />
        </Stack>
      </Stack>
    </>
  );
};

export default SpecialCircumstancesForm;
