import { useState } from "react";

import _ from "lodash";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Stack from "components/Stack";
import BasicButton from "components/BasicButton";
import Text from "components/Text";
import Divider from "components/Divider";
import IconButton from "components/IconButton";
import Button from "components/Button";
import NoteActivityBox from "components/NoteActivityBox";
import MessageActivityBox from "components/MessageActivityBox";
import Box from "components/Box";

import { colors } from "theme";

import { EditIcon, RedEnvelopeIcon, RedPlusIcon, RedUserIcon } from "icons";

import { isIMessages, useFamilyDetail } from "entities/FamilySpecialist/sdk";
import { getEmploymentStatusDisplayValue } from "entities/FamilyMember/sdk";
import EditPanel from "entities/FamilyEdit/components";
import ContactInformation from "entities/FamilyEdit/components/ContactInformation";
import AuthorizationSection from "entities/FamilyEdit/components/AuthorizationSection";
import ParentStatus from "entities/FamilyEdit/components/ParentStatus";
import Children from "entities/FamilyEdit/components/Children";
import Summary from "entities/FamilyEdit/components/Summary";

import SendMessageDialog from "entities/Messaging/SendMessageDialog";
import AddNoteDialog from "entities/FamilyEdit/AddNoteDialog";
import ReassignFamilyDialog from "entities/FamilyEdit/ReassignFamilyDialog";

import { formatToCamelCase } from "utils/format";
import PlacementList from "entities/FamilyEdit/components/Placement";
import { getMissingFieldsBullets } from "./utils";
import NestedList from "components/NestedList";
import FamilySpecialistDocumentsListCard from "entities/Documents/componenets/FamilySpecialistDocumentsListCard";
import DependentsList from "entities/FamilyEdit/components/DependentsList";

dayjs.extend(relativeTime);

const FamilyDetailPage = () => {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const id = Number(params.id);

  const [open, setOpen] = useState(false);
  const [openSendMessage, setOpenSendMessage] = useState(false);
  const [openAddNote, setOpenAddNote] = useState(false);
  const [openReassignFamily, setOpenReassignFamily] = useState(false);
  const [currentContent, setCurrentContent] = useState<React.ReactNode | null>(
    null
  );
  const [saveHandler, setSaveHandler] = useState<
    (() => Promise<boolean>) | null
  >(null);

  const handleOpen = (content: React.ReactNode) => () => {
    setCurrentContent(content);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSaveHandler(null);
  };

  const handleMessageOpen = () => {
    setOpenSendMessage(true);
  };

  const handleMessageClose = () => {
    setOpenSendMessage(false);
    refetchFamilyDetail();
  };

  const handleAddNoteOpen = () => {
    setOpenAddNote(true);
  };

  const handleAddNoteClose = () => {
    setOpenAddNote(false);
    refetchFamilyDetail();
  };

  const handleReassignFamilyOpen = () => {
    setOpenReassignFamily(true);
  };

  const handleReassiiignFamilyClose = () => {
    setOpenReassignFamily(false);
    refetchFamilyDetail();
  };

  const registerSaveHandler = (handler: () => Promise<boolean>) => {
    setSaveHandler(() => handler);
  };

  const handleSaveClick = async () => {
    if (saveHandler) {
      const isSuccess = await saveHandler();
      if (isSuccess) {
        setOpen(false);
      }
    } else {
      setOpen(false);
    }
  };

  const handleAddToWaitlist = () => {
    window.open(
      "https://earlyedncareop.eec.state.ma.us/KinderWait/ActivityPages/ApplicationPages/AddNewApplication.aspx",
      "_blank"
    );
  };

  const { data: familyData, mutate: refetchFamilyDetail } = useFamilyDetail(id);

  if (!familyData) {
    return null;
  }

  const { notes, messages } = familyData;
  const timelineItems = _.sortBy(
    [...notes, ...messages],
    (item) => -dayjs(item.created_at).unix()
  );
  const missingFieldsBullets = getMissingFieldsBullets(familyData);

  const showOnWaitlistButton = _.some(
    familyData.vouchers,
    (voucher) => voucher.status !== "ON_WAITLIST" && voucher.status !== "ISSUED"
  );

  const handleChipClick = (fileUrl: string) => {
    window.open(fileUrl, "_blank");
  };

  return (
    <Stack sx={{ minHeight: "calc(100vh - 60px)" }} gap="20px">
      <Stack direction="row" spacing={2}>
        <div style={{ flex: 3 }}>
          <Stack
            sx={{
              p: "30px",
              borderRadius: "16px",
              backgroundColor: colors.surface,
            }}
          >
            <Stack spacing={2}>
              <Stack direction="row" justifyContent="space-between" gap="15px">
                <Text
                  variant="titleLarge"
                  color={colors.onSurface}
                  fontWeight={"600"}
                >
                  {familyData?.creator.first_name}{" "}
                  {familyData?.creator.last_name}
                </Text>
                <Stack direction="row" alignItems="center" gap="100px">
                  {familyData?.household_id && (
                    <Text variant="titleMedium">
                      HHID # {familyData?.household_id}
                    </Text>
                  )}
                  {familyData?.family_id && (
                    <Text variant="titleMedium">
                      FID # {familyData?.family_id}
                    </Text>
                  )}
                </Stack>
              </Stack>
              <Stack
                direction={"row"}
                style={{ marginBottom: "10px", marginTop: "20px" }}
              >
                <Text
                  variant="titleMedium"
                  color={colors.onSurfaceVariant}
                  fontWeight={"600"}
                >
                  {t("familyAdmin")}:{" "}
                  {familyData.specialist
                    ? familyData.specialist.first_name +
                      " " +
                      familyData.specialist.last_name
                    : t("none")}
                </Text>
              </Stack>
              <Divider />

              <Stack spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text variant="titleMedium" color={colors.onSurfaceVariant}>
                    {t("summary")}
                  </Text>
                  <IconButton
                    onClick={handleOpen(
                      <Summary
                        summary={familyData.creator.child_care_need_summary}
                        familyId={id}
                        refetchFamilyDetail={refetchFamilyDetail}
                        registerSaveHandler={registerSaveHandler}
                      />
                    )}
                  >
                    <EditIcon width="24px" height="24px" />
                  </IconButton>
                </Stack>
                <Text variant="bodyLarge">
                  {familyData?.creator.child_care_need_summary}
                </Text>
              </Stack>

              <Divider />

              <Stack spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text variant="titleMedium" color={colors.onSurfaceVariant}>
                    {t("contactInformation")}
                  </Text>
                  <IconButton
                    onClick={handleOpen(
                      <ContactInformation
                        familyData={familyData}
                        refetchFamilyDetail={refetchFamilyDetail}
                        registerSaveHandler={registerSaveHandler}
                      />
                    )}
                  >
                    <EditIcon width="24px" height="24px" />
                  </IconButton>
                </Stack>
                <Text variant="bodyLarge">
                  <Text
                    variant="bodyLarge"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {familyData?.creator.preferred_language}{" "}
                  </Text>
                  preferred
                </Text>
                {familyData?.creator.email_contact_methods &&
                  familyData?.creator.email_contact_methods
                    .filter((email) => email.status === "ACTIVE")
                    .map((email, index) => (
                      <Text key={"email-" + index} variant="bodyLarge">
                        {email.email}
                      </Text>
                    ))}
                {familyData?.creator.phone_contact_methods &&
                  familyData?.creator.phone_contact_methods
                    .filter((phone) => phone.status === "ACTIVE")
                    .map((phone, index) => (
                      <Text key={"phone-" + index} variant="bodyLarge">
                        {phone.phone_number}
                      </Text>
                    ))}
                <Text variant="bodyLarge">{familyData?.city}</Text>
              </Stack>

              <Divider />

              <AuthorizationSection familyId={id} />

              <Divider />

              <Stack spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text variant="titleMedium" color={colors.onSurfaceVariant}>
                    {t("parentStatus")}
                  </Text>
                  <IconButton
                    onClick={handleOpen(
                      <ParentStatus refetchFamilyDetail={refetchFamilyDetail} />
                    )}
                  >
                    <EditIcon width="24px" height="24px" />
                  </IconButton>
                </Stack>
                {familyData?.family_members.map((parent) => (
                  <Stack
                    justifyContent="space-between"
                    direction="row"
                    key={parent.id}
                  >
                    <Text
                      variant="bodyLarge"
                      sx={{ flex: 2 }}
                      fontWeight="bold"
                    >
                      {parent.first_name} {parent.last_name}
                    </Text>
                    <Text variant="bodyLarge" sx={{ flex: 2 }}>
                      <Text variant="bodyLarge" fontWeight="bold">
                        {parent.employment_status &&
                          getEmploymentStatusDisplayValue(
                            parent.employment_status
                          )}
                      </Text>{" "}
                      {parent.employment_status === "EMPLOYED" &&
                        parent.employer_name}{" "}
                      {parent.employment_status === "OTHER" &&
                        parent.other_status_description}
                    </Text>
                    <Text variant="bodyLarge" sx={{ flex: 1 }}></Text>
                  </Stack>
                ))}
              </Stack>

              <Divider />

              <Stack spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text variant="titleMedium" color={colors.onSurfaceVariant}>
                    {t("children")}
                  </Text>
                  <IconButton
                    onClick={handleOpen(
                      <Children refetchFamilyDetail={refetchFamilyDetail} />
                    )}
                  >
                    <EditIcon width="24px" height="24px" />
                  </IconButton>
                </Stack>
                {familyData?.children.map((child) => (
                  <Stack
                    justifyContent="space-between"
                    direction="row"
                    key={child.id}
                  >
                    <Text
                      variant="bodyLarge"
                      sx={{ flex: 2 }}
                      fontWeight="bold"
                    >
                      {child.first_name} {child.last_name}
                    </Text>
                    <Text variant="bodyLarge" sx={{ flex: 2 }}>
                      {" "}
                      {child.age} yrs
                    </Text>
                    <Text sx={{ flex: 1 }}></Text>
                  </Stack>
                ))}
              </Stack>

              <Divider />
              <Stack spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text variant="titleMedium" color={colors.onSurfaceVariant}>
                    {t("dependents")}
                  </Text>
                  <IconButton
                    onClick={handleOpen(
                      <DependentsList
                        refetchFamilyDetail={refetchFamilyDetail}
                      />
                    )}
                  >
                    <EditIcon width="24px" height="24px" />
                  </IconButton>
                </Stack>

                {familyData.dependents.map((dependent) => (
                  <Stack
                    key={dependent.id}
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Text
                      variant="bodyLarge"
                      sx={{ flex: 2 }}
                      fontWeight="bold"
                    >
                      {dependent.first_name} {dependent.last_name}
                    </Text>
                    <Text variant="bodyLarge" sx={{ flex: 3 }}>
                      DOB: {dayjs(dependent.date_of_birth).format("MM/DD/YYYY")}
                    </Text>
                  </Stack>
                ))}
              </Stack>
              <Divider />
              <Stack spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text variant="titleMedium" color={colors.onSurfaceVariant}>
                    {t("placement")}
                  </Text>
                  <IconButton
                    onClick={handleOpen(
                      <PlacementList
                        refetchFamilyDetail={refetchFamilyDetail}
                      />
                    )}
                  >
                    <EditIcon width="24px" height="24px" />
                  </IconButton>
                </Stack>
                {familyData.vouchers.map((voucher, voucherIndex) =>
                  voucher.placements.map((placement, placementIndex) => (
                    <Stack
                      key={`${voucherIndex}${placementIndex}`}
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Text
                        variant="bodyLarge"
                        sx={{ flex: 2 }}
                        fontWeight="bold"
                      >
                        {placement.provider_name}
                      </Text>
                      <Text variant="bodyLarge" sx={{ flex: 2 }}>
                        {placement.children
                          .map(
                            (child) => `${child.first_name} ${child.last_name}`
                          )
                          .join(", ")}
                      </Text>
                      <Text
                        variant="bodyLarge"
                        sx={{ flex: 1, textAlign: "right" }}
                      >
                        {" "}
                        {placement.child_care_need &&
                          t(formatToCamelCase(placement.child_care_need))}
                      </Text>
                    </Stack>
                  ))
                )}
              </Stack>
            </Stack>
          </Stack>
          {timelineItems.length > 0 && (
            <Stack
              sx={{
                p: "20px 0",
                borderRadius: "16px",
              }}
            >
              <Text variant="titleMedium" color={colors.onSurfaceVariant}>
                {t("activity")}
              </Text>
              <Stack spacing={2} py={2}>
                {timelineItems.map((item, index) => {
                  return (
                    <Box
                      p={2}
                      borderRadius={2}
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                      key={index}
                      sx={{ backgroundColor: colors.surface }}
                    >
                      {isIMessages(item) ? (
                        <MessageActivityBox
                          item={item}
                          handleChipClick={handleChipClick}
                        />
                      ) : (
                        <NoteActivityBox item={item} />
                      )}
                    </Box>
                  );
                })}
              </Stack>
            </Stack>
          )}
        </div>

        <Stack flex={1} gap="30px">
          {showOnWaitlistButton && (
            <Stack
              spacing={2}
              sx={{
                p: "15px",
                borderRadius: "16px",
                backgroundColor: colors.surface,
              }}
            >
              <Text variant="titleMedium">{t("actionNeeded")}</Text>
              <Divider />
              <BasicButton
                label={t("addToWaitlist")}
                onClick={handleAddToWaitlist}
              />
            </Stack>
          )}
          <Stack
            sx={{
              p: "15px",
              borderRadius: "16px",
              backgroundColor: colors.surface,
            }}
          >
            <Text variant="titleMedium" sx={{ mb: "15px" }}>
              {t("suggestedActions")}
            </Text>
            <Divider />
            <Stack spacing={2} sx={{ mt: "15px", width: "50%" }}>
              <Button
                variant="outlined"
                startIcon={<RedPlusIcon />}
                onClick={handleAddNoteOpen}
                sx={{
                  borderColor: colors.primary,
                  color: "#000",
                  textTransform: "none",
                  justifyContent: "flex-start",
                  "&:hover": {
                    borderColor: colors.primary,
                  },
                }}
              >
                {t("addNote")}
              </Button>
              <Button
                variant="outlined"
                startIcon={<RedEnvelopeIcon />}
                onClick={handleMessageOpen}
                sx={{
                  borderColor: colors.primary,
                  color: "#000",
                  textTransform: "none",
                  justifyContent: "flex-start",
                  "&:hover": {
                    borderColor: colors.primary,
                  },
                }}
              >
                {t("sendMessage")}
              </Button>
              <Button
                variant="outlined"
                startIcon={<RedUserIcon />}
                onClick={handleReassignFamilyOpen}
                sx={{
                  borderColor: colors.primary,
                  color: "#000",
                  textTransform: "none",
                  justifyContent: "flex-start",
                  "&:hover": {
                    borderColor: colors.primary,
                  },
                }}
              >
                {t("reassign")}
              </Button>
            </Stack>
          </Stack>
          {missingFieldsBullets.length > 0 && (
            <Stack
              spacing={2}
              sx={{
                p: "15px",
                borderRadius: "16px",
                backgroundColor: colors.surface,
              }}
            >
              <Stack gap="15px" sx={{ mb: "12px" }}>
                <Text variant="titleMedium" color={colors.onSurface}>
                  {t("missingInformation")}
                </Text>
                <Divider />
              </Stack>
              <NestedList list={missingFieldsBullets} />
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                style={{ marginTop: "12px", marginBottom: "12px" }}
              >
                <Text
                  fontWeight="500"
                  variant="labelLarge"
                  color={colors.onSurfaceVariant}
                ></Text>
              </Stack>
            </Stack>
          )}
          <FamilySpecialistDocumentsListCard familyId={id} />
        </Stack>
      </Stack>

      <EditPanel
        open={open}
        onClose={handleClose}
        title="Edit Panel"
        data={familyData}
        onSaveClick={handleSaveClick}
      >
        {currentContent}
      </EditPanel>
      <SendMessageDialog
        open={openSendMessage}
        onClose={handleMessageClose}
        familyId={id}
        preferredContactMethod={familyData.creator.preferred_contact_method}
      />
      <AddNoteDialog
        open={openAddNote}
        onClose={handleAddNoteClose}
        familyId={id}
      />
      <ReassignFamilyDialog
        open={openReassignFamily}
        onClose={handleReassiiignFamilyClose}
        familyId={id}
      />
    </Stack>
  );
};

export default FamilyDetailPage;
