import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import FullPageDialog from "components/FullPageDialog";

import { URLS } from "config/urls";

import DependentForm from "entities/Dependent/components/Form";
import { useMyFamily } from "entities/Family/sdk";
import {
  IDependent,
  incomeEligibleDependentUpdate,
} from "entities/Dependent/sdk";
import { reverse } from "utils/urls";

const DependentEditPage = () => {
  const { t } = useTranslation();
  const { dependentId: dependentIdParam } = useParams<{
    dependentId: string;
  }>();
  const dependentId = parseInt(String(dependentIdParam));
  const navigate = useNavigate();
  const { data: family } = useMyFamily();
  const dependent = family?.dependents.find(
    (dependent) => dependent.id === dependentId
  );
  const { voucherId: voucherIdParam } = useParams<{ voucherId: string }>();
  const voucherId = voucherIdParam ? parseInt(voucherIdParam) : undefined;

  if (!voucherId) {
    return null;
  }

  const handleSave = (data: Partial<IDependent>) => {
    return incomeEligibleDependentUpdate({
      dependentId,
      data,
      voucherId,
    }).then(() => {
      navigate(
        reverse(URLS.VERIFY_INCOME_DEPENDENTS_LIST, {
          voucherId,
        })
      );
    });
  };
  return (
    <FullPageDialog
      title={t("dependentInformation")}
      backUrl={reverse(URLS.VERIFY_INCOME_DEPENDENTS_LIST, {
        voucherId,
      })}
      closeUrl={URLS.HOME}
    >
      <DependentForm
        initialValues={dependent}
        onSave={handleSave}
        vouchers={family?.vouchers}
      />
    </FullPageDialog>
  );
};

export default DependentEditPage;
