import BasicButton from "components/BasicButton";
import Checkbox from "components/Checkbox";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import FileUploadBox from "components/FileUploadBox";
import FormControlLabel from "components/FormControlLabel";
import IconButton from "components/IconButton";
import Stack from "components/Stack";
import { CloseIcon } from "icons";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { colors } from "theme";
import StyledSelect from "components/StyledSelect";
import { useFamilyDetail } from "entities/FamilySpecialist/sdk";
import { formatToCamelCase } from "utils/format";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  documentBooleansToLabels,
  uploadDocument,
} from "entities/Documents/sdk";
import FormHelperText from "components/FormHelperText";
import { toast } from "react-toastify";

interface IProps {
  open: boolean;
  onClose: () => void;
  familyId: number;
}

const defaultValues = {
  document_type: "",
  voucher: "",
  parent: "",
  child: "",
  placement: "",
  childFields: {
    citizenship_verification: false,
    age_verification: false,
    relationship_verification: false,
  },
  parentFields: {
    id_verification: false,
    military_retirement_verification: false,
    military_active_duty_verification: false,
    school_or_training_verification: false,
    income_verification: false,
  },
  familyFields: {
    residency_verification: false,
  },
  placementFields: {
    provider_confirmation: false,
  },
};

const FamilySpecialistDocumentUpload = ({
  open,
  onClose,
  familyId,
}: IProps) => {
  const { data: family } = useFamilyDetail(familyId);
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const { t } = useTranslation();

  const handleClose = () => {
    setFile(null);
    reset();
    onClose();
  };

  const {
    control,
    reset,
    formState: { isSubmitting, errors },
    register,
    unregister,
    handleSubmit,
    watch,
  } = useForm({ defaultValues });

  const documentType = watch("document_type");
  const authorizationId = watch("voucher");

  const onSubmit: SubmitHandler<typeof defaultValues> = async (data) => {
    if (!file) {
      return setFileError(t("fieldRequired"));
    }

    await uploadDocument({
      file,
      voucherId: data.voucher,
      familyId: familyId,
      parentId: data.parent,
      childId: data.child,
      placementId: data.placement,
      childFields: data.childFields,
      parentFields: data.parentFields,
      familyFields: data.familyFields,
      placementFields: data.placementFields,
    });

    handleClose();
    toast.success(t("fileUploadSuccess", { filename: file.name }));
  };

  useEffect(() => {
    if (documentType === "family") {
      unregister("parent");
      unregister("child");
      unregister("parentFields");
      unregister("childFields");
      unregister("placement");
      unregister("placementFields");
    } else if (documentType === "child") {
      unregister("parent");
      unregister("parentFields");
      unregister("familyFields");
      unregister("placement");
      unregister("placementFields");
    } else if (documentType === "parent") {
      unregister("child");
      unregister("childFields");
      unregister("familyFields");
      unregister("placement");
      unregister("placementFields");
    } else if (documentType === "placement") {
      unregister("parent");
      unregister("child");
      unregister("parentFields");
      unregister("childFields");
      unregister("familyFields");
    }
  }, [register, unregister, documentType]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          backgroundColor: colors.surfaceContainerLow,
        },
      }}
    >
      <DialogTitle>
        Upload Documents
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon style={{ fill: colors.onSurfaceVariant }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            pt: "30px",
            borderTop: "1px solid",
            borderColor: colors.outlineVariant,
          }}
        >
          <FileUploadBox file={file} setFile={setFile} />
          {fileError && (
            <FormHelperText error sx={{ mt: 2 }}>
              {fileError}
            </FormHelperText>
          )}
          <Stack
            sx={{
              mx: (theme) => theme.spacing(1),
              py: (theme) => theme.spacing(2),
            }}
          >
            <Controller
              name="voucher"
              control={control}
              rules={{ required: t("fieldRequired") }}
              render={({ field }) => (
                <StyledSelect
                  {...field}
                  label="Authorization"
                  options={
                    family?.vouchers.map((voucher) => ({
                      label: `${t(formatToCamelCase(voucher.type))}- ${dayjs(voucher.created_at).format("MM/DD/YYYY")}`,
                      value: voucher.id,
                    })) || []
                  }
                  fullWidth
                  margin="normal"
                  required
                  error={!!errors.voucher}
                  helperText={errors.voucher?.message}
                />
              )}
            />

            <Controller
              name="document_type"
              control={control}
              rules={{ required: t("fieldRequired") }}
              render={({ field }) => (
                <StyledSelect
                  {...field}
                  label="Document Type"
                  options={[
                    { label: "Family Document", value: "family" },
                    { label: "Child Document", value: "child" },
                    { label: "Parent Document", value: "parent" },
                    { label: "Placement Document", value: "placement" },
                  ]}
                  fullWidth
                  margin="normal"
                  required
                  error={!!errors.document_type}
                  helperText={errors.document_type?.message}
                />
              )}
            />

            {documentType === "parent" && (
              <Controller
                name="parent"
                control={control}
                rules={{ required: t("fieldRequired") }}
                render={({ field }) => (
                  <StyledSelect
                    {...field}
                    label="Parent"
                    options={
                      family?.family_members.map((member) => ({
                        label: `${member.first_name} ${member.last_name}`,
                        value: member.id,
                      })) || []
                    }
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.parent}
                    helperText={errors.parent?.message}
                  />
                )}
              />
            )}
            {documentType === "child" && (
              <Controller
                name="child"
                control={control}
                rules={{ required: t("fieldRequired") }}
                render={({ field }) => (
                  <StyledSelect
                    {...field}
                    label="Child"
                    options={
                      family?.children.map((child) => ({
                        label: `${child.first_name} ${child.last_name}`,
                        value: child.id,
                      })) || []
                    }
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.child}
                    helperText={errors.child?.message}
                  />
                )}
              />
            )}

            {documentType === "placement" && (
              <Controller
                name="placement"
                control={control}
                rules={{ required: t("fieldRequired") }}
                render={({ field }) => (
                  <StyledSelect
                    {...field}
                    label="Placement"
                    options={
                      family?.vouchers
                        .find(
                          (voucher) =>
                            voucher.id.toString() === authorizationId.toString()
                        )
                        ?.placements.map((placement) => ({
                          label: placement.provider_name || "Unknown Name",
                          value: placement.id,
                        })) || []
                    }
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.placement}
                    helperText={errors.placement?.message}
                  />
                )}
              />
            )}

            {documentType === "parent" && (
              <>
                <Controller
                  name="parentFields.id_verification"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={documentBooleansToLabels["id_verification"]}
                    />
                  )}
                />
                <Controller
                  name="parentFields.military_retirement_verification"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={
                        documentBooleansToLabels[
                          "military_retirement_verification"
                        ]
                      }
                    />
                  )}
                />
                <Controller
                  name="parentFields.military_active_duty_verification"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={
                        documentBooleansToLabels[
                          "military_active_duty_verification"
                        ]
                      }
                    />
                  )}
                />
                <Controller
                  name="parentFields.school_or_training_verification"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={
                        documentBooleansToLabels[
                          "school_or_training_verification"
                        ]
                      }
                    />
                  )}
                />
                <Controller
                  name="parentFields.income_verification"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={documentBooleansToLabels["income_verification"]}
                    />
                  )}
                />
              </>
            )}

            {documentType === "child" && (
              <>
                <Controller
                  name="childFields.citizenship_verification"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={
                        documentBooleansToLabels["citizenship_verification"]
                      }
                    />
                  )}
                />
                <Controller
                  name="childFields.age_verification"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={documentBooleansToLabels["age_verification"]}
                    />
                  )}
                />
                <Controller
                  name="childFields.relationship_verification"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={
                        documentBooleansToLabels["relationship_verification"]
                      }
                    />
                  )}
                />
              </>
            )}

            {documentType === "family" && (
              <Controller
                name="familyFields.residency_verification"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={documentBooleansToLabels["residency_verification"]}
                  />
                )}
              />
            )}
            {documentType === "placement" && (
              <Controller
                name="placementFields.provider_confirmation"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={documentBooleansToLabels["provider_confirmation"]}
                  />
                )}
              />
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", mb: "20px" }}>
        <BasicButton
          label={"Upload"}
          onClick={handleSubmit(onSubmit)}
          isDisabled={isSubmitting}
        />
      </DialogActions>
    </Dialog>
  );
};

export default FamilySpecialistDocumentUpload;
