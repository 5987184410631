import { Controller, useForm } from "react-hook-form";
import theme, { colors } from "theme";

import Stack from "components/Stack";
import Checkbox from "components/Checkbox";
import FormControlLabel from "components/FormControlLabel";
import BasicButton from "components/BasicButton";
import { useTranslation } from "react-i18next";
import Container from "components/Container";
import Text from "components/Text";

interface IDefaultValues {
  has_wages_from_employment: boolean;
  has_self_employment_income: boolean;
  is_seeking_work_or_education: boolean;
  is_attending_school_or_training: boolean;
  has_other_income: boolean;
  has_assets_over_1m: boolean;
}

interface IProps {
  defaultValues: IDefaultValues;
  parent: { first_name: string; last_name: string };
  onSave: (data: IDefaultValues) => void;
}

const VerifyEligibilityForm = ({ defaultValues, parent, onSave }: IProps) => {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm({
    defaultValues,
  });

  return (
    <>
      <Container maxWidth="sm" sx={{ flex: 1, marginTop: theme.spacing(2) }}>
        <Text
          component="p"
          variant="titleMedium"
          color={colors.onSurface}
          sx={{ mb: theme.spacing(2) }}
        >
          {t("whichOfTheFollowingApply", {
            parentName: `${parent.first_name} ${parent.last_name}`,
          })}
        </Text>
        <Stack gap={theme.spacing(2)} flex={1} sx={{ mt: theme.spacing(4) }}>
          <Controller
            name={"has_wages_from_employment"}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label={t("label_has_wages_from_employment")}
              />
            )}
          />
          <Controller
            name={"has_self_employment_income"}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label={t("label_has_self_employment_income")}
              />
            )}
          />
          <Controller
            name={"is_seeking_work_or_education"}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label={t("label_is_seeking_work_or_education")}
              />
            )}
          />
          <Controller
            name={"is_attending_school_or_training"}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label={t("label_is_attending_school_or_training")}
              />
            )}
          />
          <Controller
            name={"has_other_income"}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label={t("label_has_other_income")}
              />
            )}
          />
          <Controller
            name={"has_assets_over_1m"}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label={t("label_has_assets_over_1m")}
              />
            )}
          />
        </Stack>
      </Container>
      <div
        style={{
          padding: theme.spacing(2, 3),
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <BasicButton label={t("next")} onClick={handleSubmit(onSave)} />
      </div>
    </>
  );
};

export default VerifyEligibilityForm;
