import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import Container from "components/Container";
import FullPageDialog from "components/FullPageDialog";
import PrimaryContactForm from "entities/PrimaryContact/components/Form";

import { familyUpdate, IFamily, useMyFamily } from "entities/Family/sdk";

import { URLS } from "config/urls";
import { DeepPartial } from "utils/common";
import { reverse } from "utils/urls";

const PrimaryContactPage = () => {
  const { t } = useTranslation();
  const { data: family } = useMyFamily();
  const navigate = useNavigate();
  const { voucherId: voucherIdParam } = useParams<{ voucherId: string }>();

  const missingFieldsForThisParent: string[] =
    family?.missing_fields
      .flatMap((voucher) => voucher.missing_fields.parent_missing_fields)
      .filter(
        (parentMissingFields) =>
          parentMissingFields.id === family.creator.id &&
          parentMissingFields.missing_fields.length > 0
      )
      .flatMap((parentMissingFields) => parentMissingFields.missing_fields) ||
    [];

  const creatorMissingFieldsForThisParent = missingFieldsForThisParent.map(
    (field) => `creator.${field}`
  );

  const familyMissingFieldsForAllVouchers: string[] =
    family?.missing_fields.flatMap(
      (voucher) => voucher.missing_fields.family_missing_fields
    ) || [];

  const fieldsWithWarnings = [
    ...creatorMissingFieldsForThisParent,
    ...familyMissingFieldsForAllVouchers,
  ];

  if (!family || !voucherIdParam) {
    return null;
  }

  const handleSave = (data: DeepPartial<IFamily>) => {
    return familyUpdate({ data }).then(() => {
      navigate(
        reverse(URLS.MISSING_INFORMATION_PARENTS, { voucherId: voucherIdParam })
      );
    });
  };
  return (
    <FullPageDialog
      title={t("primaryContact")}
      progressBarPercentage={10}
      closeUrl={URLS.HOME}
    >
      <Container maxWidth="xs">
        <PrimaryContactForm
          initialValues={family}
          fieldsWithWarnings={fieldsWithWarnings}
          onSave={handleSave}
        />
      </Container>
    </FullPageDialog>
  );
};

export default PrimaryContactPage;
