import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AddButton from "components/AddButton";
import BasicButton from "components/BasicButton";
import Container from "components/Container";
import FullPageDialog from "components/FullPageDialog";
import Link from "components/Link";
import Stack from "components/Stack";
import Text from "components/Text";
import useMediaQuery from "components/useMediaQuery";

import PlacementListItem from "entities/Placement/components/ListItem";

import { useMyFamily, IFamily } from "entities/Family/sdk";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";
import { snakeCaseToCapitalisedText } from "utils/common";
import { colors } from "theme";

const PlacementListPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: family } = useMyFamily();
  const matches = useMediaQuery("(min-width:600px)");
  const { voucherId: voucherIdParam } = useParams<{ voucherId: string }>();

  const voucher = family?.vouchers.find(
    (voucher) => voucher.id.toString() === voucherIdParam
  );

  const placements = voucher?.placements || [];

  const hasMissingFields = (
    placement: IFamily["vouchers"][number]["placements"][number]
  ) => {
    if (!family?.missing_fields) {
      return false;
    }

    return (
      family.missing_fields
        .flatMap((voucher) => voucher.missing_fields.placement_missing_fields)
        .filter(
          (placementMissingFields) =>
            placementMissingFields.id === placement.id &&
            placementMissingFields.missing_fields.length > 0 &&
            !(
              placementMissingFields.missing_fields.length === 1 &&
              placementMissingFields.missing_fields[0] ===
                "Missing confirmation document for provider"
            )
        ).length > 0
    );
  };

  const voucherAndChildWithMissingPlacement: Array<{
    voucher_id: number;
    missing_fields: {
      child_with_missing_placement: Array<{ child_id: number }>;
    };
  }> =
    family?.missing_fields.filter(
      (voucher) =>
        voucher.voucher_id.toString() === voucherIdParam &&
        voucher.missing_fields.child_with_missing_placement.length > 0
    ) || [];

  if (!placements || !family || !voucherIdParam) {
    return null;
  }

  return (
    <FullPageDialog
      title={t("placements")}
      progressBarPercentage={60}
      backUrl={reverse(URLS.MISSING_INFORMATION_VERIFICATION, {
        voucherId: voucherIdParam,
      })}
      closeUrl={URLS.HOME}
    >
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 2,
          flex: 1,
        }}
      >
        <Text
          variant="titleMedium"
          color={colors.onSurface}
          sx={{ mb: (theme) => theme.spacing(2) }}
        >
          {t("provideChildcareInfo")}
        </Text>
        {placements.map((placement) => (
          <PlacementListItem
            placement={placement}
            voucher={placement.voucher}
            onEdit={() => {
              navigate(
                reverse(URLS.MISSING_INFORMATION_PLACEMENT_EDIT, {
                  placementId: placement.id,
                  voucherId: voucherIdParam,
                })
              );
            }}
            hasMissingFields={hasMissingFields(placement)}
          />
        ))}
        <Link
          to={reverse(URLS.MISSING_INFORMATION_PLACEMENT_ADD, {
            voucherId: voucherIdParam,
          })}
          style={{ display: "components", width: matches ? "auto" : "100%" }}
        >
          <AddButton
            label={t("addPlacement")}
            backgroundColor={colors.secondaryContainer}
          />
        </Link>
        {voucherAndChildWithMissingPlacement.length > 0 && (
          <Stack spacing={2} sx={{ marginTop: (theme) => theme.spacing(2) }}>
            {voucherAndChildWithMissingPlacement.map((voucher) => (
              <Text color="error">
                {t("missingPlacementForAuthorization")}{" "}
                {snakeCaseToCapitalisedText(
                  family?.vouchers.find(
                    (familyVoucher) => familyVoucher.id === voucher.voucher_id
                  )?.type || ""
                )}{" "}
                {t("forChildren")}:{" "}
                <b>
                  {family?.children
                    .filter(
                      (child) =>
                        voucher.missing_fields.child_with_missing_placement
                          .map((c) => c.child_id)
                          .indexOf(child.id) > -1
                    )
                    .map((child) => `${child.first_name} ${child.last_name}`)
                    .join(", ")}
                </b>
              </Text>
            ))}
          </Stack>
        )}
      </Container>
      <Stack
        sx={{
          alignItems: "center",
          borderTop: "1px solid " + colors.outlineVariant,
          padding: (theme) => theme.spacing(4, 1),
        }}
      >
        <Link
          to={
            placements.length > 0
              ? reverse(
                  URLS.MISSING_INFORMATION_PROVIDER_CONFIRMATION_FOR_PLACEMENT,
                  {
                    placementId: placements[0].id,
                    voucherId: voucherIdParam,
                  }
                )
              : reverse(URLS.MISSING_INFORMATION_REFERRAL_SUCCESS, {
                  voucherId: voucherIdParam,
                })
          }
          style={{ width: matches ? "auto" : "100%" }}
        >
          <BasicButton label={t("next")} />
        </Link>
      </Stack>
    </FullPageDialog>
  );
};

export default PlacementListPage;
