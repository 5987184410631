import Divider from "components/Divider";
import IconButton from "components/IconButton";
import Text from "components/Text";
import Stack from "components/Stack";
import { IDocument, useFamilyDetail } from "entities/FamilySpecialist/sdk";
import {
  ArrowRightIcon,
  CheckIcon,
  DocumentsIcon,
  ImageIcon,
  PlusIcon,
} from "icons";
import { colors } from "theme";
import { useTranslation } from "react-i18next";
import Link from "components/Link";
import Chip from "components/Chip";
import FamilySpecialistDocumentUpload from "../FamilySpecialistDocumentUpload";
import { useState } from "react";
import { documentBooleansToLabels } from "entities/Documents/sdk";
interface IProps {
  familyId: number;
}
const getDocumentLabels = (document: IDocument): string[] => {
  const labels: string[] = [];
  for (const [key, value] of Object.entries(documentBooleansToLabels)) {
    if (document[key as keyof IDocument]) labels.push(value);
  }

  return labels;
};

const FamilySpecialistDocumentsListCard = ({ familyId }: IProps) => {
  const { t } = useTranslation();
  const { data: familyData, mutate: refetchFamilyDetail } =
    useFamilyDetail(familyId);

  const [isUploadModalOpen, setUploadModalOpen] = useState(false);

  const handleUploadDocumentsOpen = () => {
    setUploadModalOpen(true);
  };

  const handleUploadDocumentsClose = () => {
    setUploadModalOpen(false);
    refetchFamilyDetail();
  };

  if (!familyData) {
    return null;
  }

  return (
    <Stack
      spacing={2}
      sx={{
        p: "15px",
        borderRadius: "16px",
        backgroundColor: colors.surface,
      }}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Text variant="titleMedium">{t("documents")}</Text>
        <IconButton onClick={handleUploadDocumentsOpen}>
          <PlusIcon />
        </IconButton>
      </Stack>
      <Divider />
      {familyData.documents.map((document) => (
        <>
          <Stack
            key={document.id}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} alignItems={"center"} gap="15px">
              {document.file_type === "application/pdf" ? (
                <DocumentsIcon />
              ) : (
                <ImageIcon />
              )}
              <Text
                variant="labelLarge"
                fontWeight="500"
                sx={{ wordBreak: "break-word" }}
              >
                {document.original_file_name}
              </Text>
            </Stack>
            <Link to={document.file} external target="_blank">
              <IconButton>
                <ArrowRightIcon />
              </IconButton>
            </Link>
          </Stack>
          <Stack direction={"row"} gap="5px" flexWrap={"wrap"}>
            {getDocumentLabels(document).map((label) => (
              <Chip icon={<CheckIcon />} label={<Text>{label}</Text>} />
            ))}
          </Stack>
          <Divider />
        </>
      ))}
      <FamilySpecialistDocumentUpload
        open={isUploadModalOpen}
        onClose={handleUploadDocumentsClose}
        familyId={familyId}
      />
    </Stack>
  );
};

export default FamilySpecialistDocumentsListCard;
