import { useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Stack from "components/Stack";
import Text from "components/Text";
import BasicButton from "components/BasicButton";
import useMediaQuery from "components/useMediaQuery";
import IconButton from "components/IconButton";
import TextField from "components/TextField";
import Divider from "components/Divider";
import FormControl from "components/FormControl";
import Radio, { RadioGroup } from "components/Radio";
import FormControlLabel from "components/FormControlLabel";
import FormHelperText from "components/FormHelperText";

import { URLS } from "config/urls";
import { colors } from "theme";
import { QuestionMarkIcon } from "icons";

import {
  incomeVerificationUpdate,
  useHouseholdSize,
} from "entities/FamilyMember/sdk";

const IncomeVerification = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      household_size: null,
      annual_income_status: "",
    },
    mode: "onChange",
  });

  const householdSize = Number(watch("household_size"));
  const annualIncomeStatus = watch("annual_income_status");
  const [validatedSize, setValidatedSize] = useState<null | number>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (householdSize >= 2 && householdSize <= 12) {
      setValidatedSize(householdSize);
    } else {
      setValidatedSize(null);
    }
  }, [householdSize]);

  const { data: householdData } = useHouseholdSize(validatedSize!);

  const onSubmit = () => {
    if (householdData) {
      setIsSubmitting(true);
      const data = {
        household_size: validatedSize!,
        annual_income_status: annualIncomeStatus,
        total_annual_income: householdData.annual_income_per_year,
      };

      incomeVerificationUpdate(data)
        .then(() => {
          navigate(URLS.FAMILY_INFORMATION_SPECIAL_CIRCUMSTANCES);
          setIsSubmitting(false);
        })
        .catch(() => {
          const errorMessage = t("errorSavingData");
          toast.error(errorMessage);
          setIsSubmitting(false);
        });
    }
  };

  return (
    <>
      <Stack
        sx={{ mt: "15px", width: matches ? "400px" : "auto" }}
        alignSelf="center"
        alignItems="center"
      >
        <Text variant="titleMedium" sx={{ mb: 2 }}>
          {t("financialAssistanceAvailable")}
        </Text>
        <Text variant="titleMedium" sx={{ mt: 2 }}>
          {t("incomeCutoffDetails")}
        </Text>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ mt: "15px", mb: "15px" }}
        >
          <IconButton
            onClick={() =>
              navigate(URLS.FAMILY_INFORMATION_HOUSEHOLD_DEFINITIONS)
            }
          >
            <QuestionMarkIcon />
          </IconButton>
          <Text
            variant="titleMedium"
            sx={{ mt: "5px", color: colors.onSurfaceVariant }}
          >
            {t("helpWithHouseholdSizeIncome")}
          </Text>
        </Stack>
        <Text
          variant="titleLarge"
          color={colors.onSurfaceVariant}
          sx={{ alignSelf: "flex-start" }}
        >
          {t("householdSize")}:
        </Text>
        <Controller
          name="household_size"
          control={control}
          rules={{
            required: t("fieldRequired"),
            min: { value: 2, message: t("numberRangeError") },
            max: { value: 12, message: t("numberRangeError") },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              type="number"
              label={t("enterNumberOfYourHousehold")}
              fullWidth
              margin="normal"
              inputProps={{ min: "2", max: "12", step: "1" }}
              error={!!errors.household_size}
              helperText={
                errors.household_size ? errors.household_size.message : ""
              }
            />
          )}
        />
        {householdSize && !errors.household_size ? (
          <Stack
            alignItems="center"
            sx={{
              background: colors.surfaceContainerHigh,
              width: "100%",
              padding: "20px",
              mt: "10px",
            }}
          >
            <Stack sx={{ pb: "20px" }}>
              <Text
                variant="labelMedium"
                color={colors.onSurfaceVariant}
                sx={{ textAlign: "center" }}
              >
                {t("incomeCutoffLabel")}:
              </Text>
              <Text variant="titleLarge" sx={{ textAlign: "center" }}>
                {t("incomeCutoffYear", {
                  yearAmount:
                    householdData?.annual_income_per_year.toLocaleString(
                      "en-US"
                    ),
                })}
              </Text>
            </Stack>
            <Divider sx={{ width: "100%" }} />
            <Stack sx={{ pt: "20px" }}>
              <Text
                variant="labelMedium"
                color={colors.onSurfaceVariant}
                sx={{ textAlign: "center" }}
              >
                {t("whichIs")}
              </Text>
              <Text variant="titleMedium" sx={{ textAlign: "center" }}>
                {t("incomeCutoffMonth", {
                  monthAmount:
                    householdData?.annual_income_per_month.toLocaleString(
                      "en-US"
                    ),
                })}
              </Text>
            </Stack>
          </Stack>
        ) : null}
        {householdSize && !errors.household_size ? (
          <Stack>
            <Text
              variant="titleMedium"
              sx={{ mt: "30px", alignSelf: "flex-start" }}
            >
              {t("totalHouseholdIncome")}
            </Text>
            <FormControl
              component="fieldset"
              margin="normal"
              sx={{ width: "100%" }}
            >
              <Controller
                name="annual_income_status"
                control={control}
                rules={{ required: t("optionSelectRequired") }}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    aria-label="employment status"
                    sx={{ flexDirection: "column" }}
                  >
                    <FormControlLabel
                      value="MORE_THAN"
                      control={<Radio />}
                      label={t("moreThan", {
                        yearAmount:
                          householdData?.annual_income_per_year.toLocaleString(
                            "en-US"
                          ),
                      })}
                      sx={{
                        borderBottom: "1px solid",
                        borderColor: colors.outlineVariant,
                        marginRight: 0,
                        padding: "10px 0",
                      }}
                    />
                    <FormControlLabel
                      value="LESS_THAN"
                      control={<Radio />}
                      label={t("lessThan", {
                        yearAmount:
                          householdData?.annual_income_per_year.toLocaleString(
                            "en-US"
                          ),
                      })}
                      sx={{
                        borderBottom: "1px solid",
                        borderColor: colors.outlineVariant,
                        marginRight: 0,
                        padding: "10px 0",
                      }}
                    />
                    <FormControlLabel
                      value="NOT_WORKING"
                      control={<Radio />}
                      label={t("notWorking")}
                      sx={{
                        borderBottom: "1px solid",
                        borderColor: colors.outlineVariant,
                        marginRight: 0,
                        padding: "10px 0",
                      }}
                    />
                  </RadioGroup>
                )}
              />
              {!!errors.annual_income_status && (
                <FormHelperText error sx={{ fontSize: "13px", mt: "15px" }}>
                  {errors.annual_income_status.message}
                </FormHelperText>
              )}
            </FormControl>
          </Stack>
        ) : null}
      </Stack>
      <Stack
        sx={{
          borderTop: matches ? "1px solid" : "none",
          borderColor: colors.outline,
          marginTop: "30px",
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
          }}
        >
          <BasicButton
            label={t("next")}
            onClick={handleSubmit(onSubmit)}
            isDisabled={isSubmitting}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default IncomeVerification;
