import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import AddButton from "components/AddButton";
import Stack from "components/Stack";
import EditPanel from "entities/FamilyEdit/components/index";
import { useData } from "../DataContext";
import DependentItem from "entities/Dependent/components/ListItem";
import {
  IDependent,
  dependentCreateAsSpecialist,
  dependentUpdateAsSpecialist,
} from "entities/Dependent/sdk";
import DependentForm, {
  DependentFormRef,
} from "entities/Dependent/components/FormAsSpecialist";

interface IProps {
  refetchFamilyDetail: () => void;
}

const DependentsList = ({ refetchFamilyDetail }: IProps) => {
  const { t } = useTranslation();
  const familyData = useData();
  const [isAdding, setIsAdding] = useState(false);
  const [selectedDependent, setSelectedDependent] = useState<
    IDependent | undefined
  >();
  const addDependentFormRef = useRef<DependentFormRef>(null);

  const handleAddDependentClick = () => {
    setSelectedDependent(undefined);
    setIsAdding(true);
  };

  const handleEditDependent = (dependent: IDependent) => {
    setSelectedDependent(dependent);
    setIsAdding(true);
  };

  const handleSaveClick = () => {
    if (addDependentFormRef.current) {
      addDependentFormRef.current.submitForm();
    }
  };

  const handleSaveForm = (
    data: Partial<{
      first_name: string;
      last_name: string;
      date_of_birth: string;
    }>
  ) => {
    if (!familyData?.id) {
      return Promise.reject();
    }
    const isUpdatingDependent = Boolean(selectedDependent);

    if (isUpdatingDependent) {
      return dependentUpdateAsSpecialist({
        data,
        dependentId: selectedDependent!.id,
      }).then(() => {
        setIsAdding(false);
        refetchFamilyDetail();
      });
    } else {
      return dependentCreateAsSpecialist({
        familyId: familyData.id,
        data,
      }).then(() => {
        setIsAdding(false);
        refetchFamilyDetail();
      });
    }
  };

  if (familyData === null) {
    return null;
  }
  return (
    <Stack spacing={2}>
      <EditPanel
        open={isAdding}
        onClose={() => setIsAdding(false)}
        title={t(selectedDependent ? "editDependent" : "addDependent")}
        onSaveClick={handleSaveClick}
      >
        <DependentForm
          onSave={handleSaveForm}
          initialData={selectedDependent}
          ref={addDependentFormRef}
        />
      </EditPanel>
      {familyData.dependents.map((dependent) => (
        <DependentItem
          key={dependent.id}
          dependent={dependent}
          onEdit={() => handleEditDependent(dependent)}
        />
      ))}
      <Stack alignItems="center" style={{ marginBottom: "20px" }}>
        <AddButton
          label={t("addDependent")}
          onClick={handleAddDependentClick}
        />
      </Stack>
    </Stack>
  );
};

export default DependentsList;
