import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import { useTranslation } from "react-i18next";

import TextField from "components/TextField";
import Text from "components/Text";
import Stack from "components/Stack";
import FormControl from "components/FormControl";
import Radio, { RadioGroup } from "components/Radio";
import FormControlLabel from "components/FormControlLabel";
import FormHelperText from "components/FormHelperText";
import Divider from "components/Divider";
import Button from "components/Button";
import BasicButton from "components/BasicButton";
import useMediaQuery from "components/useMediaQuery";
import Container from "components/Container";

import { colors } from "theme";

import { useHouseholdSize } from "entities/FamilyMember/sdk";

interface IIncomeVerificationFormProps {
  voucher: {
    household_size: number;
    annual_income_status: string;
  };
  onSave: (data: any) => void;
}

const IncomeVerificationForm = ({
  voucher,
  onSave,
}: IIncomeVerificationFormProps) => {
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      household_size: voucher.household_size,
      annual_income_status: voucher.annual_income_status,
    },
    mode: "onChange",
  });
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:600px)");

  const householdSize = Number(watch("household_size"));
  const [validatedSize, setValidatedSize] = useState<null | number>(null);

  useEffect(() => {
    if (householdSize >= 2 && householdSize <= 12) {
      setValidatedSize(householdSize);
    } else {
      setValidatedSize(null);
    }
  }, [householdSize]);

  const { data: householdData } = useHouseholdSize(validatedSize!);

  useEffect(() => {
    reset({
      household_size: voucher.household_size,
      annual_income_status: voucher.annual_income_status,
    });
  }, [voucher, reset]);

  return (
    <>
      <Container
        maxWidth="xs"
        sx={{
          margin: "30px auto",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Text variant="titleMedium" sx={{ mb: 2 }}>
          {t("financialAssistanceAvailable")}
        </Text>
        <Text variant="titleMedium" sx={{ mt: 2 }}>
          {t("incomeCutoffDetails")}
        </Text>
        <Text
          variant="titleLarge"
          color={colors.onSurfaceVariant}
          sx={{ alignSelf: "flex-start", mt: 2 }}
        >
          {t("householdSize")}:
        </Text>
        <Controller
          name="household_size"
          control={control}
          rules={{
            required: t("fieldRequired"),
            min: { value: 2, message: t("numberRangeError") },
            max: { value: 12, message: t("numberRangeError") },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              type="number"
              label={t("enterNumberOfYourHousehold")}
              fullWidth
              margin="normal"
              inputProps={{ min: "2", max: "12", step: "1" }}
              error={!!errors.household_size}
              helperText={
                errors.household_size ? errors.household_size.message : ""
              }
            />
          )}
        />
        {householdSize && !errors.household_size ? (
          <Stack
            alignItems="center"
            sx={{
              background: colors.surfaceContainerHigh,
              width: "100%",
              padding: "20px",
              mt: "10px",
            }}
          >
            <Stack sx={{ pb: "20px" }}>
              <Text
                variant="labelMedium"
                color={colors.onSurfaceVariant}
                sx={{ textAlign: "center" }}
              >
                {t("incomeCutoffLabel")}:
              </Text>
              <Text variant="titleLarge" sx={{ textAlign: "center" }}>
                {t("incomeCutoffYear", {
                  yearAmount:
                    householdData?.annual_income_per_year.toLocaleString(
                      "en-US"
                    ),
                })}
              </Text>
            </Stack>
            <Divider sx={{ width: "100%" }} />
            <Stack sx={{ pt: "20px" }}>
              <Text
                variant="labelMedium"
                color={colors.onSurfaceVariant}
                sx={{ textAlign: "center" }}
              >
                {t("whichIs")}
              </Text>
              <Text variant="titleMedium" sx={{ textAlign: "center" }}>
                {t("incomeCutoffMonth", {
                  monthAmount:
                    householdData?.annual_income_per_month.toLocaleString(
                      "en-US"
                    ),
                })}
              </Text>
            </Stack>
          </Stack>
        ) : null}
        {householdSize && !errors.household_size ? (
          <Stack sx={{ width: "100%" }}>
            <Text
              variant="titleMedium"
              sx={{ mt: "30px", alignSelf: "flex-start" }}
            >
              {t("totalHouseholdIncome")}
            </Text>
            <FormControl
              component="fieldset"
              margin="normal"
              sx={{ width: "100%" }}
            >
              <Controller
                name="annual_income_status"
                control={control}
                rules={{ required: t("optionSelectRequired") }}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    aria-label="employment status"
                    sx={{ flexDirection: "column" }}
                  >
                    <FormControlLabel
                      value="MORE_THAN"
                      control={<Radio />}
                      label={t("moreThan", {
                        yearAmount:
                          householdData?.annual_income_per_year.toLocaleString(
                            "en-US"
                          ),
                      })}
                      sx={{
                        borderBottom: "1px solid",
                        borderColor: colors.outlineVariant,
                        marginRight: 0,
                        padding: "10px 0",
                      }}
                    />
                    <FormControlLabel
                      value="LESS_THAN"
                      control={<Radio />}
                      label={t("lessThan", {
                        yearAmount:
                          householdData?.annual_income_per_year.toLocaleString(
                            "en-US"
                          ),
                      })}
                      sx={{
                        borderBottom: "1px solid",
                        borderColor: colors.outlineVariant,
                        marginRight: 0,
                        padding: "10px 0",
                      }}
                    />
                    <FormControlLabel
                      value="NOT_WORKING"
                      control={<Radio />}
                      label={t("notWorking")}
                      sx={{
                        borderBottom: "1px solid",
                        borderColor: colors.outlineVariant,
                        marginRight: 0,
                        padding: "10px 0",
                      }}
                    />
                  </RadioGroup>
                )}
              />
              {!!errors.annual_income_status && (
                <FormHelperText error sx={{ fontSize: "13px", mt: "15px" }}>
                  {errors.annual_income_status.message}
                </FormHelperText>
              )}
            </FormControl>
          </Stack>
        ) : null}
      </Container>
      <Stack
        sx={{
          borderTop: "1px solid " + colors.outline,
          padding: (theme) => theme.spacing(4, 1),
          alignItems: "center",
        }}
      >
        <Button
          style={{ width: matches ? "auto" : "100%" }}
          onClick={handleSubmit((data) =>
            onSave({
              ...data,
              total_annual_income: householdData?.annual_income_per_year || 0,
            })
          )}
        >
          <BasicButton label={t("next")} />
        </Button>
      </Stack>
    </>
  );
};

export default IncomeVerificationForm;
