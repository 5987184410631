import { forwardRef, useEffect, useImperativeHandle } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import dayjs from "dayjs";

import Stack from "components/Stack";
import TextField from "components/TextField";
import FormControl from "components/FormControl";
import Radio, { RadioGroup } from "components/Radio";
import FormControlLabel from "components/FormControlLabel";
import StyledSelect from "components/StyledSelect";

import { ParentData } from "entities/FamilyEdit/sdk";
import {
  parentCreateAsSpecialist,
  parentUpdateAsSpecialist,
} from "entities/Parent/sdk";
import { IFamilyMember } from "entities/FamilySpecialist/sdk";
import {
  useGenderOptions,
  useLanguageOptions,
} from "entities/FamilyMember/sdk";

import { colors } from "theme";
import DateInput from "components/DateInput";

interface IProps {
  onSave: (data: ParentData) => void;
  familyId: number;
  initialData?: IFamilyMember;
  refetchFamilyDetail: () => void;
}

export interface AddParentFormRef {
  submitForm: () => void;
}

const AddParentForm = forwardRef<AddParentFormRef, IProps>(
  ({ onSave, familyId, initialData, refetchFamilyDetail }, ref) => {
    const { t } = useTranslation();
    const {
      control,
      watch,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm<ParentData>({
      defaultValues: {
        ...initialData,
        date_of_birth: initialData?.date_of_birth
          ? dayjs(initialData.date_of_birth).format("MM/DD/YYYY")
          : undefined,
      },
    });
    const employmentStatus = watch("employment_status");
    const genderOptions = useGenderOptions();
    const languageOptions = useLanguageOptions();

    useImperativeHandle(ref, () => ({
      submitForm: handleSubmit(onSubmit),
    }));

    useEffect(() => {
      if (initialData) {
        const formattedInitialData = {
          ...initialData,
          date_of_birth: initialData.date_of_birth
            ? dayjs(initialData.date_of_birth).format("MM/DD/YYYY")
            : "",
        };
        reset(formattedInitialData);
      } else {
        reset();
      }
    }, [initialData, reset]);

    const onSubmit = (data: ParentData) => {
      const formattedBirthDate = data.date_of_birth
        ? dayjs(data.date_of_birth, "MM/DD/YYYY").format("YYYY-MM-DD")
        : undefined;
      const parentData = {
        ...data,
        date_of_birth: formattedBirthDate,
      };

      const saveParent = initialData
        ? parentUpdateAsSpecialist({
            parentId: initialData.id,
            data: parentData,
          })
        : parentCreateAsSpecialist({ familyId, data: parentData });

      saveParent
        .then(() => {
          onSave(data);
          refetchFamilyDetail();
        })
        .catch(() => {
          const errorMessage = t("errorSavingData");
          toast.error(errorMessage);
        });
    };

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} sx={{ padding: 2 }}>
          <Controller
            name="preferred_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("preferredName")}
                error={!!errors.preferred_name}
                helperText={errors.preferred_name?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="first_name"
            control={control}
            rules={{ required: t("This field is required") }}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("firstName")}
                error={!!errors.first_name}
                helperText={errors.first_name?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="last_name"
            control={control}
            rules={{ required: t("This field is required") }}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("lastName")}
                error={!!errors.last_name}
                helperText={errors.last_name?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("gender")}
                options={genderOptions}
                fullWidth
                margin="normal"
                error={!!errors.gender}
                helperText={errors.gender?.toString()}
              />
            )}
          />
          <Controller
            name="preferred_language"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("preferredLanguage")}
                options={languageOptions}
                fullWidth
                margin="normal"
                error={!!errors.preferred_language}
                helperText={errors.preferred_language?.message}
              />
            )}
          />
          <DateInput
            control={control}
            label={t("dateOfBirth")}
            name="date_of_birth"
            required={false}
          />
          <FormControl
            component="fieldset"
            margin="normal"
            sx={{ width: "100%" }}
          >
            <Controller
              name="employment_status"
              control={control}
              defaultValue={initialData?.employment_status || undefined}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  row
                  aria-label="employment status"
                  sx={{ flexDirection: "column" }}
                >
                  <FormControlLabel
                    value="EMPLOYED"
                    control={<Radio />}
                    label={t("iAmEmployed")}
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: colors.outlineVariant,
                      marginRight: 0,
                      padding: "10px 0",
                    }}
                  />
                  <FormControlLabel
                    value="IN_SCHOOL"
                    control={<Radio />}
                    label={t("iAmInSchool")}
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: colors.outlineVariant,
                      marginRight: 0,
                      padding: "10px 0",
                    }}
                  />
                  <FormControlLabel
                    value="TRAININGPROGRAM"
                    control={<Radio />}
                    label={t("iAmInTraining")}
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: colors.outlineVariant,
                      marginRight: 0,
                      padding: "10px 0",
                    }}
                  />
                  <FormControlLabel
                    value="OTHER"
                    control={<Radio />}
                    label={t("other")}
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: colors.outlineVariant,
                      marginRight: 0,
                      padding: "10px 0",
                    }}
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
          {employmentStatus === "EMPLOYED" && (
            <Controller
              name="employer_name"
              control={control}
              render={({ field }) => (
                <TextField {...field} label={t("employerName")} fullWidth />
              )}
            />
          )}
          {employmentStatus === "OTHER" && (
            <Controller
              name="other_status_description"
              control={control}
              render={({ field }) => (
                <TextField {...field} label={t("other")} fullWidth />
              )}
            />
          )}
        </Stack>
      </form>
    );
  }
);

export default AddParentForm;
