import { PreferredContactMethod } from "entities/FamilyEdit/sdk";

import { BASE_URL, post } from "utils/sdk";

export interface IVerifyInformationPrimaryContact {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  date_of_birth: string;
  preferred_contact_method: PreferredContactMethod;
  consent_text: boolean;
  consent_email: boolean;
  preferred_language: string;
}

export interface IIncomeEligiblePrimaryContact {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  phone_number: string;
  email: string;
  preferred_contact_method: PreferredContactMethod;
  preferred_language: string;
}

export const incomeEligiblePrimaryContactUpdate = ({
  data,
}: {
  data: Partial<IIncomeEligiblePrimaryContact>;
}) =>
  post(
    `${BASE_URL}/api/family-members/income-eligible/primary-contact/update/ `,
    data
  );
